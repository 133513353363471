import { schema } from 'normalizr';
import {XtlClient} from './xtl-client';

export interface XtlUser {
    id: string;
    civility: string;
    lastname: string;
    firstname: string;
    email: string;
    phoneNumber: string;
    function: string;
    client: XtlClient;
    role: string;
    comment: string;
    active: boolean;
    updatedAt: Date;
    createdAt: Date;
}

const clientSchema = new schema.Entity('clients');

const userSchema = new schema.Entity('users', {
            client: clientSchema
});

const userListSchema = [userSchema];

export {userSchema};

export {userListSchema};

