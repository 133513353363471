import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { StoreService } from 'src/app/service/store.service';
import { XtlHabilitation } from 'src/app/model/xtl-habilitation';
import { ActivatedRoute, Router } from '@angular/router';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import {formatDate, Location} from '@angular/common';
import {FormatValuesService} from '../../service/format-values.service';
import {DurationDerogation} from '../../enums/duration-derogation.enum';
import {DateCalculatorService} from '../../service/date-calculator.service';
import {FormValidatorService} from '../../service/form-validator.service';
import {take} from 'rxjs/operators';


@Component({
    selector: 'app-page-habilitation-detail',
    templateUrl: './page-habilitation-detail.component.html',
    styleUrls: ['./page-habilitation-detail.component.scss'],
})
export class PageHabilitationDetailComponent implements OnInit {

    habilitationForm: FormGroup;
    habilitation: XtlHabilitation;
    submitted = false;
    itemId: string;
    showConfirmDialog: boolean = false;
    errorModalTitle: string;
    errorModalMsg: string;

    // lists for form select
    userOptions: {label: string, value: string}[];
    exemptionPeriodOptions: {label: string, value: number}[];
    formateurOptions: {label: string, value: string}[];
    formationOptions: { label: string; value: string; }[];

    constructor(
        private formBuilder: FormBuilder,
        public store: StoreService,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private formatValuesService: FormatValuesService,
        private dateCalculatorService: DateCalculatorService,
        private router: Router,
        public formValidator: FormValidatorService
    ) {
    }

    ngOnInit() {
        this.habilitationForm = this.formBuilder.group({
            holder: ['', Validators.required],
            formation: ['', Validators.required],
            formateur: ['', Validators.required],
            formationDate: [new Date(), Validators.required],
            deadlineDate: ['', [Validators.required]],
            exemptionPeriod: [null],
            inRenew: [false],
            reminderDesactivation: [false]
        });

        this.itemId = this.activatedRoute.snapshot.paramMap.get('id');
        if (this.itemId)
        {
            this.getSelectedItem();
        }
        this.getUsersOption();
        this.getFormateursOption();
        this.getFormationsOption();

        this.exemptionPeriodOptions = this.formatValuesService.formatFilterValues(Object.values(DurationDerogation), true, 'Pas de dérogation');
    }

    get f() { return this.habilitationForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.habilitationForm.invalid) {
            return;
        }
        let action: XtlAction = {} as XtlAction;

        // check if the client have a RH client
        let noRHClientForClientError: boolean = false;
        this.store.selectUsers(this.habilitationForm.value.holder).pipe(take(1)).subscribe(
            user => {
                // Check if the RH client for the client exist
                let rhClients = this.store.getRhClient(user.client.id);
                if (rhClients.length === 0) {
                    noRHClientForClientError = true;
                    this.errorModalTitle = 'Aucun Rh Client définit pour ce client';
                    this.errorModalMsg = 'Vous ne pouvez pas créer une habilitation pour un client dont la société n\'a pas de RH Client associé';
                    this.showConfirmDialog = true;
                    return;
                }
                // check if the habilitation is already in the database
                this.store.selectHabilitations().pipe(take(1)).subscribe(
                    habilitations => {
                        let habilitationDuplicateError: boolean;
                        habilitations.forEach(habilitation => {
                            if (
                                habilitation.holder.id === this.habilitationForm.controls.holder.value &&
                                habilitation.formation.id === this.habilitationForm.controls.formation.value &&
                                formatDate(habilitation.formationDate, 'shortDate', 'fr') === formatDate(this.habilitationForm.controls.formationDate.value, 'shortDate', 'fr')

                            ) {
                                // avoid the error on update
                                if (this.itemId) {
                                    if (this.itemId !== habilitation.id.toString()) {
                                        // set error to true if a duplicate is found
                                        habilitationDuplicateError = true;
                                    }
                                }
                                else {
                                    habilitationDuplicateError = true;
                                }
                            }
                        });
                        if (!habilitationDuplicateError) {
                            // add the new habilitation or update if no duplicate error
                            this.habilitationForm.controls.formateur.setValue('/api/formateurs/' + this.habilitationForm.controls.formateur.value);
                            this.habilitationForm.controls.holder.setValue('/api/users/' + this.habilitationForm.controls.holder.value);
                            this.habilitationForm.controls.formation.setValue('/api/formations/' + this.habilitationForm.controls.formation.value);
                            // add 15h to the date Time object
                            this.habilitationForm.controls.formationDate.setValue(this.dateCalculatorService.dateSetHour(this.habilitationForm.controls.formationDate.value, 12));
                            this.habilitationForm.controls.deadlineDate.setValue(this.dateCalculatorService.dateSetHour(this.habilitationForm.controls.deadlineDate.value, 12));
                            if (this.itemId){
                                action = {
                                    type: ActionTypes.UPDATE_HABILITATION,
                                    data: Object.assign(this.habilitationForm.value, {id: this.itemId})
                                };
                            } else {
                                action = {
                                    type: ActionTypes.ADD_HABILITATION,
                                    data: Object.assign(this.habilitationForm.value, {id: null})
                                };
                            }
                            this.store.dispatch(action);
                            this.location.back();
                        }
                        else {
                            // show the modal if a duplicate error if found and set the error message
                            this.errorModalTitle = 'Doublon habilitation';
                            this.errorModalMsg = 'Une habilitation avec le même titulaire, la même formation et la même date de formation existe déjà. Vous ne pouvez pas en créer une nouvelle.';
                            this.showConfirmDialog = true;
                        }
                    }
                );
            }
        );
    }

    onCancel() {
        this.location.back();
    }

    getSelectedItem(){
        // if needed fetch Users
        this.store.selectUsers().pipe(take(1)).subscribe(
            users => {
                if (users.length === 0) {
                    const fetchUser: XtlAction = {
                        type: ActionTypes.FETCH_USER,
                        data: null
                    };
                    this.store.dispatch(fetchUser);
                }
            });
        // if needed fetch Users and clients
        this.store.selectClients().pipe(take(1)).subscribe(
            clients => {
                if (clients.length === 0) {
                    const fetchClient: XtlAction = {
                        type: ActionTypes.FETCH_CLIENT,
                        data: null
                    };
                    this.store.dispatch(fetchClient);
                }
            });

        const fetchFormation: XtlAction = {
            type: ActionTypes.FETCH_FORMATION,
            data: null
        };
        const fetchFormateur: XtlAction = {
            type: ActionTypes.FETCH_FORMATEUR,
            data: null
        };
        this.store.dispatch(fetchFormation);
        this.store.dispatch(fetchFormateur);
        this.store.selectHabilitations(this.itemId).subscribe(
            habilitation => {
                if (habilitation) {
                    this.habilitation = habilitation;
                    this.habilitationForm.controls.holder.setValue(this.habilitation.holder.id);
                    this.habilitationForm.controls.formation.setValue(this.habilitation.formation.id);
                    this.habilitationForm.controls.formateur.setValue(this.habilitation.formateur.id);
                    this.habilitationForm.controls.formationDate.setValue(new Date(this.habilitation.formationDate));
                    this.habilitationForm.controls.deadlineDate.setValue(new Date(this.habilitation.deadlineDate));
                    this.habilitationForm.controls.exemptionPeriod.setValue(this.habilitation.exemptionPeriod);
                    this.habilitationForm.controls.inRenew.setValue(this.habilitation.inRenew);
                    this.habilitationForm.controls.reminderDesactivation.setValue(this.habilitation.reminderDesactivation);
                } else if (this.store.getCurrentUser() != null){
                    const action: XtlAction = {
                        type: ActionTypes.FETCH_HABILITATION,
                        data: null
                    };
                    this.store.dispatch(action);
                }
            });
    }

    onAddClick(){
        this.router.navigate(['/user-create']);
    }

    getUsersOption(){
        this.store.selectUsers().subscribe(
            users => {
                if (users.length != 0) {
                    users.sort(this.formatValuesService.dynamicSort('firstname'));
                    this.userOptions = new Array();
                    users.filter( user => user.active).forEach(user => {
                        this.userOptions.push({label: user.firstname + ' ' + user.lastname + ' (' + user.client.name + ')', value: user.id});
                    });
                } else if (this.store.getCurrentUser() != null){
                    const action: XtlAction = {
                        type: ActionTypes.FETCH_USER,
                        data: null
                    };
                    this.store.dispatch(action);
                }
            }
        );
    }

    getFormateursOption(){
        this.store.selectFormateurs().subscribe(
            formateurs => {
                if (formateurs.length != 0) {
                    this.formateurOptions = new Array();
                    formateurs.filter( formateur => formateur.active).forEach(formateur => {
                        this.formateurOptions.push({
                            label: formateur.firstname + ' ' + formateur.lastname,
                            value: formateur.id
                        });
                    });
                } else if (this.store.getCurrentUser() != null){
                    const action: XtlAction = {
                        type: ActionTypes.FETCH_FORMATEUR,
                        data: null
                    };
                    this.store.dispatch(action);
                }
            }
        );
    }

    getFormationsOption() {
        this.store.selectFormations().subscribe(
            formations => {
                if (formations.length != 0) {
                    this.formationOptions = new Array();
                    formations.filter( formation => formation.active).forEach(formation => {
                        this.formationOptions.push({label: formation.reference, value: formation.id});
                    });
                } else if (this.store.getCurrentUser() != null){
                    const action: XtlAction = {
                        type: ActionTypes.FETCH_FORMATION,
                        data: null
                    };
                    this.store.dispatch(action);
                }
            }
        );
    }

    updateDeadlineDate() {
        if (this.habilitationForm.controls.formation.value !== '' && this.habilitationForm.controls.formationDate.value !== '') {
            this.store.selectFormations(this.habilitationForm.controls.formation.value).subscribe(
                formation => {
                    this.habilitationForm.controls.deadlineDate.setValue(this.dateCalculatorService.dateAddDuration(this.habilitationForm.controls.formationDate.value, formation.validityPeriod));
                }
            );
        }
    }

    onExemptionPeriodSelect() {
        // if exemptionPeriod is set in renew set to true
        if (this.habilitationForm.controls.exemptionPeriod.value !== '') {
            this.habilitationForm.controls.inRenew.setValue(true);
            // call on renewSelect
            this.onInRenewSelect();
        }
    }
    onInRenewSelect() {
        // if inRenew is set to true in reminderDesactivation set to true
        if (this.habilitationForm.controls.inRenew.value) {
            this.habilitationForm.controls.reminderDesactivation.setValue(true);
        }
    }
    onModalCancel() {
        this.showConfirmDialog = false;
    }
}
