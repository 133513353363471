import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button-base',
  templateUrl: './button-base.component.html',
  styleUrls: ['./button-base.component.scss'],
})
export class ButtonBaseComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() color: string;
  @Input() disabled: boolean;
  @Input() type: string;
}
