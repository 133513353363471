import { Component, OnInit } from '@angular/core';
import { XtlUser } from 'src/app/model/xtl-user';
import {FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import { StoreService } from 'src/app/service/store.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import {FormatValuesService} from '../../service/format-values.service';
import {Civility} from '../../enums/civility.enum';
import {UserRole} from '../../enums/user-role.enum';
import {FormValidatorService} from '../../service/form-validator.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-page-user-detail',
  templateUrl: './page-user-detail.component.html',
  styleUrls: ['./page-user-detail.component.scss'],
})
export class PageUserDetailComponent implements OnInit {

  userForm: FormGroup;
  user: XtlUser;
  submitted = false;
  itemId: string;
  showConfirmDialog: boolean = false;
  errorModalMsg: string;

  // lists for form select
  civilityOptions: {label: string, value: string}[];
  roleOptions: {label: string, value: string}[];
  clientOptions: {label: string, value: string}[];
  functionOptions: {label: string, value: string}[] = [];

  constructor(
      private formBuilder: FormBuilder,
      public store: StoreService,
      private activatedRoute: ActivatedRoute,
      private location: Location,
      private formatValuesService: FormatValuesService,
      public formValidator: FormValidatorService
  ) {
  }

  ionViewWillEnter(){
    this.itemId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.itemId)
    {
      this.getSelectedItem();
    }
  }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      civility: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,10}$'), this.existingEmailValidator()]),
      phoneNumber: [''],
      function: [''],
      role: ['', Validators.required],
      comment: [''],
      client: ['', Validators.required],
      active: [true],
    });

    this.civilityOptions = this.formatValuesService.formatFilterValues(Object.values(Civility));

    this.roleOptions = this.formatValuesService.formatFilterValues(Object.values(UserRole));
    this.getClientOption();
    this.getUsers();
  }

  existingEmailValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let error = null;
      this.store.selectUsers().subscribe(
          users => {
            users.forEach(user => {
              if (user.email === control.value && user.id != this.activatedRoute.snapshot.paramMap.get('id')) {
                error = { existingEmail: { value: control.value } };
              }
            });
          }
      );
      return error;
    };
  }

  get f() { return this.userForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    // check if update user, the rhClient role have been modified, or if the only rhClient is disabled
    if (
        this.itemId &&
        this.user.role === 'ROLE_RH_CLIENT' &&
        (!this.userForm.value.active || this.userForm.value.role !== 'ROLE_RH_CLIENT') &&
        this.store.getRhClient(this.user.client.id).length === 1
    ) {
      this.errorModalMsg = 'Attention, cette société n\'aura plus de RH, veuillez en ajouter un pour assurer le suivi des habilitations';
      this.showConfirmDialog = true;
    }
    // vérif si user activé
    else if (this.userForm.get('active').value) {
      // la société est desactivée
      this.store.selectClients(this.userForm.controls.client.value).pipe(take(1)).subscribe(
          client => {
            if (!client.active) {
              this.errorModalMsg = 'L\'utilisateur est associé à un compte client désactivé.Pour activer cet utilisateur, le compte client sera également ré-activé . Êtes vous sûr de vouloir continuer ?';
              this.showConfirmDialog = true;
            }
            else {
              this.updateUser();
              this.location.back();
            }
          }
      );
    }
    else {
      this.updateUser();
      this.location.back();
    }
  }

  onConfirm() {
    // ré-activation du client + save le user
    this.showConfirmDialog = false;
    this.updateUser();
    this.store.selectClients(this.userForm.controls.client.value).pipe(take(1)).subscribe(
        client => {
          if (!client.active) {
            client.active = true;
            delete client.users;
            let actionClient: XtlAction;
            actionClient = {
              type: ActionTypes.UPDATE_CLIENT,
              data: client
            };
            this.store.dispatch(actionClient);
          }
        }
    );
    this.location.back();
  }

  onCancel() {
    this.location.back();
  }

  getSelectedItem(){
    this.store.selectUsers(this.itemId).subscribe(
        user => {
          if (user) {
            this.user = user;
            this.userForm.controls.civility.setValue(this.user.civility);
            this.userForm.controls.firstname.setValue(this.user.firstname);
            this.userForm.controls.lastname.setValue(this.user.lastname);
            this.userForm.controls.email.setValue(this.user.email);
            this.userForm.controls.phoneNumber.setValue(this.user.phoneNumber);
            this.userForm.controls.function.setValue(this.user.function);
            this.userForm.controls.client.setValue(this.user.client.id);
            this.userForm.controls.role.setValue(this.user.role);
            this.userForm.controls.comment.setValue(this.user.comment);
            this.userForm.controls.active.setValue(this.user.active);
          } else if (this.store.getCurrentUser() != null){
            const action: XtlAction = {
              type: ActionTypes.FETCH_USER,
              data: null
            };
            this.store.dispatch(action);
          }
        });
  }

  getUsers(){
    this.store.selectUsers().subscribe(
        users => {
          if (users.length != 0){
            // this.clientOptions = new Array();
            // users.forEach(user => {
            //   this.userEmails.push(user.email);
            // });
          } else {
            const action: XtlAction = {
              type: ActionTypes.FETCH_USER,
              data: null
            };
            this.store.dispatch(action);
          }
        }
    );
  }

  getClientOption(){
    this.store.selectClients().subscribe(
        clients => {
          if (clients.length != 0){
            this.clientOptions = new Array();
            clients.forEach(client => {
              if (client.active){
                this.clientOptions.push({label: client.name, value: client.id});
              } else {
                this.clientOptions.push({label: client.name + ' (inactif)', value: client.id});
              }
            });
          } else {
            const action: XtlAction = {
              type: ActionTypes.FETCH_CLIENT,
              data: null
            };
            this.store.dispatch(action);
          }
        }
    );
  }
  updateUser() {
    let action: XtlAction = <XtlAction> {};
    // if user update
    if (this.itemId){
      let user = Object.assign(this.userForm.value, {id: this.itemId});
      user.client = '/api/clients/' + this.userForm.controls.client.value;
      action = {
        type: ActionTypes.UPDATE_USER,
        data: user
      };
    }
    // if add new client
    else {
      let user = Object.assign(this.userForm.value, {id: null});
      user.client = '/api/clients/' + this.userForm.controls.client.value;
      action = {
        type: ActionTypes.ADD_USER,
        data: user
      };
    }
    this.store.dispatch(action);
  }
  getFunction(event?) {
    this.store.selectUsers().subscribe(
        users => {
          if (users.length !== 0) {
            const functionList = [];
            users.forEach(user => {
              if (event) {
                if (user.function.toLowerCase().includes(event.query.toLowerCase())) {
                  functionList.push(user.function);
                }
              } else {
                functionList.push(user.function);
              }
            });
            this.functionOptions = this.formatValuesService.removeDuplicateValues(functionList);
          }
        }
    );
  }
}
