import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StoreService } from 'src/app/service/store.service';
import { Location } from '@angular/common';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import {FormatValuesService} from '../../service/format-values.service';
import {ReminderPeriodicityClient} from '../../enums/reminder-periodicity-client.enum';
import {RecyclingDelay} from '../../enums/recycling-delay.enum';
import {FormValidatorService} from '../../service/form-validator.service';

@Component({
  selector: 'app-page-config',
  templateUrl: './page-config.component.html',
  styleUrls: ['./page-config.component.scss'],
})
export class PageConfigComponent implements OnInit {

  configForm: FormGroup;
  config: [{id: number, code: string, value: string}];
  submitted = false;

  // lists for form select
  recyclingDelayOptions: {label: string, value: string}[];
  reminderPeriodicityOptions: {label: string, value: string}[];

  constructor(
      private formBuilder: FormBuilder,
      public store: StoreService,
      private location: Location,
      private formatValuesService: FormatValuesService,
      public formValidator: FormValidatorService
  ) {
  }

  fetchConfigs(){
    const action: XtlAction = {
      type: ActionTypes.FETCH_CONFIG,
      data: null
    };
    this.store.dispatch(action);
  }

  ngOnInit() {

    this.configForm = this.formBuilder.group({
      rhEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,10}$')]],
      servicesEmail: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,10}$')]],
      recyclingDelay: ['', Validators.required],
      reminderPeriodicity: ['', Validators.required]
    });

    this.recyclingDelayOptions = this.formatValuesService.formatFilterValues(Object.values(RecyclingDelay));
    this.reminderPeriodicityOptions = this.formatValuesService.formatFilterValues(Object.values(ReminderPeriodicityClient));

    this.store.selectConfig().subscribe(
      config => {
        this.config = config;
        if (config.length !=0){
          config.forEach(conf => {
            switch (conf.code) {
              case 'MAIL_RH_SDCEM':
                this.configForm.controls.rhEmail.setValue(conf.value);
                break;
              case 'MAIL_SERVICES':
                this.configForm.controls.servicesEmail.setValue(conf.value);
                break;
              case 'DELAI_RELANCE_RECYCLAGE':
                this.configForm.controls.recyclingDelay.setValue(conf.value);
                break;
              case 'PERIODICITE_RELANCE':
                this.configForm.controls.reminderPeriodicity.setValue(conf.value);
                break;
              default:
                break;
            }
          });

        } else {
          this.fetchConfigs();
        }
      }
    );

  }

  get f() { return this.configForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.configForm.invalid) {
      return;
    }
    if (!this.configForm.pristine) {
      this.config.forEach(conf => {
        switch (conf.code) {
          case 'MAIL_RH_SDCEM':
            this.configForm.controls.rhEmail.pristine ?
            null :
            this.store.dispatch({type: ActionTypes.UPDATE_CONFIG, data: {id: conf.id, code: 'MAIL_RH_SDCEM', value: this.configForm.controls.rhEmail.value}});
            break;
          case 'MAIL_SERVICES':
            this.configForm.controls.servicesEmail.pristine ?
            null :
            this.store.dispatch({type: ActionTypes.UPDATE_CONFIG, data: {id: conf.id, code: 'MAIL_SERVICES', value: this.configForm.controls.servicesEmail.value}});
            break;
          case 'DELAI_RELANCE_RECYCLAGE':
            this.configForm.controls.recyclingDelay.pristine ?
            null :
            this.store.dispatch({type: ActionTypes.UPDATE_CONFIG, data: {id: conf.id, code: 'DELAI_RELANCE_RECYCLAGE', value: this.configForm.controls.recyclingDelay.value}});
            break;
          case 'PERIODICITE_RELANCE':
            this.configForm.controls.reminderPeriodicity.pristine ?
            null :
            this.store.dispatch({type: ActionTypes.UPDATE_CONFIG, data: {id: conf.id, code: 'PERIODICITE_RELANCE', value: this.configForm.controls.reminderPeriodicity.value}});
            break;
          default:
            break;
        }
      });
    }

    this.location.back();
  }

  onCancel() {
    this.location.back();
  }

}
