import { schema } from 'normalizr';

export interface XtlFormation {
  id: number;
  reference: string;
  title: string;
  description: string;
  price: number;
  name: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  duration: string;
  color: string;
  validityPeriod: string;
  preRequisite: string;
}

const formationSchema = new schema.Entity('formations');

const formationListSchema = [formationSchema];

export {formationSchema};

export {formationListSchema};

