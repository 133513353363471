import {Directive, ElementRef, HostListener} from '@angular/core';
import {TemplateTagService} from '../../service/template-tag.service';

@Directive({
  selector: '[appTemplateTag]'
})
export class TemplateTagDirective {

  constructor(private templateTageService: TemplateTagService,
              private el: ElementRef) { }

  @HostListener('focus')
  @HostListener('keyup')
  getFocusElement() {
    this.templateTageService.setFocusElement(this.el);
  }
}
