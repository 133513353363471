import { Injectable } from '@angular/core';
import { XtlReducer } from '../interfaces/xtl-reducer';
import { XtlFormateur, formateurListSchema, formateurSchema } from '../model/xtl-formateur';
import { environment } from 'src/environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import { normalize } from 'normalizr';
import { NetworkService } from './network.service';
import { Observable } from 'rxjs';
import {XtlMsgService} from './xtl-msg.service';

@Injectable({
  providedIn: 'root'
})
export class FormateurService implements XtlReducer<XtlFormateur>{

  constructor(
      private networkService: NetworkService,
      private xtlMsgService: XtlMsgService
  ) { }

  create(data: Partial<XtlFormateur>): Observable<any | { error: string; }> {
    data.createdAt = new Date();

    return this.networkService
      .post(environment.apiUrl + '/formateurs', data)
      .pipe(
        tap(result => {
          this.xtlMsgService.add({
            severity: 'success',
            summary: 'Formateur créé',
            detail: 'Le formateur  <b>' + data.firstname + ' ' + data.lastname + '</b>  a été correctement créé.',
            life: 5000
          });
        }),
        map(result => {
          if (result.length !== 0) {
            return normalize(result, formateurSchema);
          } else {
            return { entities: { formateurs: {} }, result: Array };
          }
        }),
        catchError((err, caught) => {
          this.xtlMsgService.add({
            severity: 'error',
            summary: 'Erreur de création formateur',
            detail: 'La création du formateur a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  update(data: Partial<XtlFormateur>): Observable<any | { error: string; }> {
    data.updatedAt = new Date();

    return this.networkService
      .put(environment.apiUrl + '/formateurs/' + data.id, data)
      .pipe(
        tap(result => {
          this.xtlMsgService.add({
            severity: 'success',
            summary: 'Formateur modifié',
            detail: 'Le formateur <b>' + data.firstname + ' ' + data.lastname + '</b> a été correctement modifié.',
            life: 5000
          });
        }),
        map(result => normalize(result, formateurSchema)),
        catchError((err, caught) => {
          this.xtlMsgService.add({
            severity: 'error',
            summary: 'Erreur de modification formateur',
            detail: 'La modification du formateur <b>' + data.firstname + ' ' + data.lastname + '</b> a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  remove(target: XtlFormateur): Observable<true | { error: string; }> {
    throw new Error('Method not implemented.');
  }
  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl + '/formateurs')
    .pipe(
      map(result => {
        if (result.length !== 0) {
          return normalize(result, formateurListSchema);
        } else {
          return { entities: { formateurs: {} }, result: Array };
        }
      }),
      catchError((err, caught) => {
        this.xtlMsgService.add({
          severity: 'error',
          summary: 'Erreur de récupération des formateurs',
          detail: 'Le chargement des formateurs a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 10000
        });
        return [];
      })
    );
  }
}
