import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowDialogService {

  constructor() { }

  userId$ = new Subject<number>();
  displayDialog$ = new Subject<boolean>();
  callbackcode$ = new Subject<string>();

  showHolderHistoryDialog(userId, callbackcode) {
    this.userId$.next(userId);
    this.displayDialog$.next(true);
    this.callbackcode$.next(callbackcode);
  }

  hideDialog() {
    this.userId$.next(null);
    this.displayDialog$.next(false);
    this.callbackcode$.next(null);
  }
}
