import { schema } from 'normalizr';
import {XtlUser} from './xtl-user';
import {XtlFormateur} from './xtl-formateur';
import {XtlFormation} from './xtl-formation';

export interface XtlHabilitation {
    id: number;
    holder: XtlUser;
    formation: XtlFormation;
    formateur: XtlFormateur;
    deadlineDate: string;
    formationDate: string;
    exemptionPeriod: string;
    status: string;
    inRenew: boolean;
    reference: string;
    createdAt: Date;
    reminderDesactivation: boolean;
}

const clientSchema = new schema.Entity('clients');

const userSchema = new schema.Entity('users', {
            client: clientSchema
});

const formationSchema = new schema.Entity('formations');
const formateurSchema = new schema.Entity('formateurs');

const habilitationSchema = new schema.Entity('habilitations', {
    holder: userSchema,
    formation: formationSchema,
    formateur: formateurSchema
});
const habilitationListSchema = [habilitationSchema];

export {habilitationSchema};

export {habilitationListSchema};


