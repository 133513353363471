import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/service/store.service';
import { Router } from '@angular/router';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';

@Component({
  selector: 'app-page-email-list',
  templateUrl: './page-email-list.component.html',
  styleUrls: ['./page-email-list.component.scss'],
})
export class PageEmailListComponent implements OnInit {

  emailsTableDatas: any[];
  cols: any[];
  actionButtons = [
      {icon: 'pi pi-pencil', action: 'edit'}
  ];

  constructor(
    public store: StoreService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getEmailListData();
  }

  getEmailListData() {
    this.store.selectEmails().subscribe(
      emails => {
        if (emails.length !== 0){
          this.emailsTableDatas = new Array();
          emails.forEach(element => {
                const email = {
                    id: element.id,
                    email: element.mailSubject,
                };

                this.emailsTableDatas.push(email);
            });
        } else if (this.store.getCurrentUser() != null){
          const action: XtlAction = {
            type: ActionTypes.FETCH_EMAIL,
            data: null
          };
          this.store.dispatch(action);
        }
      this.cols = [
        {
            field: 'email',
            title: 'Email',
            dataType: 'string',
            sort: true,
            filterType: '',
            filterValues: [],
            class: 'width-80'
        },
      ];
      }
   );
  }

  onAction(_action: {actionType: string, id: Number}) {
      switch (_action['actionType']){
          case 'create':
              this.router.navigate(['/email-create']);
              break;
          case 'edit':
              this.router.navigate(['/email-detail/' + _action.id]);
              break;
          case 'pdf':
              break;
      }
  }

}
