import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {StoreService} from './store.service';
import {map} from 'rxjs/operators';
import {UserRole} from '../enums/user-role.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
      private router: Router,
      private storeService: StoreService) {
    storeService.state$.pipe(
        map(state => state.currentUser)
    ).subscribe(
        currentUser => {
          this.checkUserLogin(currentUser);
        }
    )
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!route.data.auth) {
      return false;
    }
    return this.isGranted(route.data.auth);
  }

  // Méthode qui permet de rediriger vers la page de login à la déconnexion par exemple
  checkUserLogin(currentUser) {
    if (!currentUser) {
      if (this.router.url !== 'activate-account' &&
          this.router.url !== 'reset-passwor' &&
          this.router.url !== 'achange-password' &&
          this.router.url !== '/') {
        this.router.navigate(['/login']);
      }
    }
  }

  public isGranted(authAction: string): boolean {
    if (!this.storeService.getCurrentUser()) {
      return false;
    }
    switch (authAction) {
      case 'CAN_CREATE_HABILITATION':
      case 'CAN_MANAGE_DATA':
      case 'CAN_SEE_ALL_HABILITATIONS':
        return [UserRole.ROLE_ADMIN, UserRole.ROLE_SERVICES].includes(this.storeService.getCurrentUser().role as UserRole);
      case 'CAN_SEE_CLIENT_HABILITATIONS':
        return [UserRole.ROLE_RH_CLIENT].includes(this.storeService.getCurrentUser().role as UserRole);
      case 'CAN_SEE_OWN_HABILITATIONS':
        return [UserRole.ROLE_CLIENT].includes(this.storeService.getCurrentUser().role as UserRole);
      default :
        return false;
    }
  }
  public isLoggedIn(): boolean {
    if (!this.storeService.getCurrentUser()) {
      return false;
    }
    return true;
  }
}
