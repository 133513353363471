import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import {TranslateService} from '@ngx-translate/core';

import {Message} from 'primeng/api';

import { StoreService } from './service/store.service';
import { ActionTypes } from './enums/action-types.enum';
import { XtlAction } from './interfaces/xtl-action';
import {DialogService} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [DialogService]
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private store: StoreService,
    private translate: TranslateService
  ) {
    this.initializeApp();
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('fr');
  }

  msgs: Message[] = [];
  isDarkTheme: boolean = false;

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.isDarkTheme = true;
    }

    const action: XtlAction = {
      type: ActionTypes.APP_INIT,
      data: null
    };
    this.store.dispatch(action);
    this.store.state$.subscribe(
      state => {
        console.groupCollapsed('State');
        console.log(state)
       console.groupEnd();
      }
    );

  }
}
