import { schema } from 'normalizr';

export interface XtlClient {
    id: string;
    name: string;
    address: string;
    zipcode: number;
    city: string;
    country: string;
    segment: string;
    //complementary_list
    additionalInfo: string;
    reminderPeriodicity: Number;
    active: boolean;
    createdAt: Date;
    updatedAt: Date;
}

const clientSchema = new schema.Entity('clients');

const clientListSchema = [clientSchema];

export {clientSchema};

export {clientListSchema};
