import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss'],
})
// Gère l'affichage des toutes les erreurs d'un champ d'un formulaire
export class FieldErrorComponent implements OnInit {

  @Input() fieldError: any;
  @Input() typeField: string;
  constructor() { }

  ngOnInit() {
  }

}
