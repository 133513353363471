import { schema } from 'normalizr';

export interface XtlFormateur {
  id: string;
  civility: string;
  lastname: string;
  firstname: string;
  active: boolean;
  updatedAt: Date;
  createdAt: Date;
}

const formateurSchema = new schema.Entity('formateurs');

const formateurListSchema = [formateurSchema];

export {formateurSchema};

export {formateurListSchema};