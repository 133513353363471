import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {XtlAction} from '../../interfaces/xtl-action';
import {ActionTypes} from '../../enums/action-types.enum';
import { StoreService } from '../store.service';
import {Router} from '@angular/router';
import {LoaderService} from '../loader.service';
import {XtlMsgService} from '../xtl-msg.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: StoreService,
                private xtlMsgService: XtlMsgService,
                private router: Router,
                private loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // Cas de l'authentification expirée
            console.log(err);
            if (err.status === 401 && this.router.url != '/login') {
                const action: XtlAction = {
                    type: ActionTypes.USER_LOGOUT,
                    data: null
                };
                this.store.dispatch(action);
                this.xtlMsgService.add({
                    severity: 'warn',
                    summary: 'Votre Session a expirée',
                    detail: 'Votre Session a expirée, merci de vous reconnecter.',
                    life: 5000
                });
                this.router.navigate(['/login']);
            }
            // remove the loader if /login-check error
            if (request.url.endsWith('login_check') && err.error.code === 401){
                this.xtlMsgService.add({
                    severity: 'error',
                    summary: 'Une erreur est survenue',
                    detail: 'Identifiants incorrects',
                    life: 5000
                });
                this.loaderService.hideLoader();
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
