import { Injectable } from '@angular/core';
import {LoaderService} from './loader.service';
import {Message, MessageService} from 'primeng/api';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class XtlMsgService {

  constructor(
      private messageService: MessageService,
      private loaderService: LoaderService
  ) { }

  add(msg: Message) {
      // display the toast only when the loader is hidden
    this.loaderService.isLoading$.pipe(take(1)).subscribe(
        isLoading => {
          if (!isLoading){
            this.messageService.add(msg);
          }
        }
    );
  }
  clear() {
    this.messageService.clear();
  }

}
