// Liste des tags
export enum MailTags {
    USER_CIVILITY = '[civilite_utilisateur]',
    USER_FIRSTNAME = '[prenom_utilisateur]',
    USER_LASTNAME = '[nom_utilisateur]',
    USER_EMAIL = '[email_utilisateur]',
    HABILITATION_REF = '[reference_habilitation]',
    HABILITATION_TITLE = '[titre_habilitation]',
    HABILITATION_DEADLINE_DATE = '[date_echeance_habilitation]'
}
