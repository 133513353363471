import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/service/store.service';
import { Router } from '@angular/router';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import {FormatValuesService} from '../../service/format-values.service';
import {DurationFormation} from '../../enums/duration-formation.enum';
import {Active} from '../../enums/active.enum';

@Component({
  selector: 'app-page-formation-list',
  templateUrl: './page-formation-list.component.html',
  styleUrls: ['./page-formation-list.component.scss'],
})
export class PageFormationListComponent implements OnInit {

  formationsTableDatas: any[];
  cols: any[];
  actionButtons = [
      {icon: 'pi pi-pencil', action: 'edit'}
  ];

  formationsRef: any[] = [];
  formationsTitleRef: any[] = [];
  durationRef: any[] = [];
  priceRef: any[] = [];
  activeRef: any[] = [];
  defaultFilter: Array<{ value: string, field: any, matchMode: string }>;

  constructor(
    public store: StoreService,
    private router: Router,
    private formatValuesService: FormatValuesService
  ) { }

  ngOnInit() {
    this.defaultFilter = [{ value: 'true', field: 'active', matchMode: 'equals' }];
    this.getFormationListData();
  }

  getFormationListData() {
    this.store.selectFormations().subscribe(
        formations => {
          if (formations && formations.length !== 0){
            this.formationsTableDatas = formations;
            this.formationsRef = [];
            this.formationsTitleRef = [];
            this.durationRef = [];
            this.priceRef = [];
            formations.forEach( formation => {
                  this.formationsRef.push(formation.reference);
                  this.formationsTitleRef.push(formation.title);
                  this.priceRef.push(formation.price);
              });
            this.durationRef = Object.values(DurationFormation);
            this.activeRef = Object.values(Active);
          } else {
            const action: XtlAction = {
              type: ActionTypes.FETCH_FORMATION,
              data: null
            };
            this.store.dispatch(action);
          }
            this.cols = [
                {
                    field: 'reference',
                    title: 'Référence',
                    dataType: 'string',
                    sort: true,
                    filterType: 'selectInput',
                    filterValues: this.formatValuesService.formatFilterValues(this.formationsRef),
                    class: 'width-20'
                },
                {
                    field: 'title',
                    title: 'Titre',
                    dataType: 'string',
                    sort: true,
                    filterType: 'selectInput',
                    filterValues: this.formatValuesService.formatFilterValues(this.formationsTitleRef),
                    class: 'width-30'
                },
                {
                    field: 'duration',
                    title: 'Durée',
                    dataType: 'string',
                    sort: true,
                    filterType: 'dropdown',
                    filterValues: this.formatValuesService.formatFilterValues(this.durationRef),
                    class: 'width-10'
                },
                {
                    field: 'price',
                    title: 'Tarif',
                    dataType: 'price',
                    sort: true,
                    filterType: 'dropdown',
                    filterValues: this.formatValuesService.formatFilterValues(this.priceRef),
                    class: 'width-10'
                },
                {
                    field: 'active',
                    title: 'Actif',
                    dataType: 'active',
                    sort: true,
                    filterType: 'dropdown',
                    defaultFilter: 'true',
                    filterValues: this.formatValuesService.formatFilterValues(this.activeRef),
                    class: 'width-10'
                },
            ];
        }
    );
  }

  onAction(_action: {actionType: string, id: number}) {
      switch (_action.actionType){
          case 'create':
              this.router.navigate(['/formation-create']);
              break;
          case 'edit':
              this.router.navigate(['/formation-detail/' + _action.id]);
              break;
          case 'pdf':

              break;
      }
  }

}
