import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';

import {environment} from '../../../environments/environment';
import { Storage } from '@ionic/storage';
import {catchError, map, switchMap} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private storage: Storage) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.storage.get('jwtToken'))
            .pipe(
                switchMap(token => {
                    const isApiUrl = request.url.startsWith(environment.apiUrl);
                    // add auth header with jwt if user is logged in and request is to the api url
                    if (token && isApiUrl) {
                        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
                    }
                    if (!request.headers.has('Content-Type')) {
                        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                    }
                    return next.handle(request).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                // do nothing for now
                            }
                            return event;
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return throwError(error);
                        })
                    );
                })
            );
    }
}
