import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { StoreService } from 'src/app/service/store.service';
import { PdfService } from '../../service/pdf.service';
import { FormatValuesService } from '../../service/format-values.service';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import { AuthGuardService } from '../../service/auth-guard.service';
import {StatusHabilitation} from '../../enums/status-habilitation.enum';
import {CallbackCode} from '../../enums/callback-code.enum';
import { take } from 'rxjs/operators';
import {DateCalculatorService} from '../../service/date-calculator.service';
import { ShowDialogService } from 'src/app/service/show-dialog.service';

@Component({
    selector: 'app-page-habilitation-list',
    templateUrl: './page-habilitation-list.component.html',
    styleUrls: ['./page-habilitation-list.component.scss'],
})
export class PageHabilitationListComponent implements OnInit{

    habilitationTableDatas: any[];
    habilitationsNorm: any;
    habilitations: any;
    cols: any[];
    actionButtons = [];
    newButtonLabel = '';
    recyclingDelay: string = '';

    habilitationsRef: any[];
    statusRef: any[] = [
        StatusHabilitation.EN_COURS,
        StatusHabilitation.ECHEANCE_IMMINENTE,
        StatusHabilitation.EN_COURS_RENOUVELLEMENT,
        StatusHabilitation.EN_COURS_RENOUVELLEMENT_DEROGATION,
        StatusHabilitation.NON_RENOUVELEE
    ];
    userRef: any[];
    clientRef: any[];
    dashDatas: {type: string, number: number, label: string}[];
    filterFromDash: string[];
    filterFromTable: string;
    userRole: string;

    constructor(
        public store: StoreService,
        private router: Router,
        private pdfService: PdfService,
        private formatValuesService: FormatValuesService,
        public authGuardService: AuthGuardService,
        private dateCalculatorService: DateCalculatorService,
        public showDialogService: ShowDialogService,
    ) {

    }

    getHabilitationListData() {

        this.habilitationTableDatas = [];
        let tmpUserRef = [];
        let tmpClientRef = [];
        let tmpHabRef = [];
        this.habilitations.forEach(hab => {
            // Les habilitations echues ne sont visible que dans l'historique
            // Les habilitations dont le user est inactif n'apparaissent pas
            if (hab.status !== StatusHabilitation.ECHUE && hab.holder.active) {
                const habilitation = {
                    id: hab.id,
                    firstname: hab.holder.firstname + ' ' + hab.holder.lastname,
                    lastname: hab.holder.lastname,
                    client: hab.holder.client.name,
                    formation: hab.formation.reference,
                    deadlineDate: hab.deadlineDate,
                    formationDate: hab.formationDate,
                    exemptionPeriod: hab.exemptionPeriod,
                    status: hab.status,
                    inRenew: hab.inRenew,
                    reminderDesactivation: hab.reminderDesactivation,
                    relanceDate: this.dateCalculatorService.dateSubstractDuration(hab.deadlineDate, this.recyclingDelay),
                };
                this.habilitationTableDatas.push(habilitation);
                // create filter references with duplicates
                tmpUserRef.push(hab.holder.firstname + ' ' + hab.holder.lastname);
                tmpClientRef.push(hab.holder.client.name);
                tmpHabRef.push(hab.formation.reference);
            }
        });
        this.dashDatas = [
            {
                type: StatusHabilitation.EN_COURS,
                number: this.habilitationTableDatas.filter(hab => hab.status === StatusHabilitation.EN_COURS).length,
                label: StatusHabilitation.EN_COURS
            },
            {
                type: StatusHabilitation.ECHEANCE_IMMINENTE,
                number: this.habilitationTableDatas.filter(hab => hab.status === StatusHabilitation.ECHEANCE_IMMINENTE).length,
                label: StatusHabilitation.ECHEANCE_IMMINENTE
            },
            {
                type: StatusHabilitation.EN_COURS_RENOUVELLEMENT,
                number: this.habilitationTableDatas.filter(hab => hab.status === StatusHabilitation.EN_COURS_RENOUVELLEMENT).length + this.habilitationTableDatas.filter(hab => hab.status === StatusHabilitation.EN_COURS_RENOUVELLEMENT_DEROGATION).length,
                label: StatusHabilitation.EN_COURS_RENOUVELLEMENT
            },
            {
                type: StatusHabilitation.NON_RENOUVELEE,
                number: this.habilitationTableDatas.filter(hab => hab.status === StatusHabilitation.NON_RENOUVELEE).length,
                label: StatusHabilitation.NON_RENOUVELEE
            }
        ];
        // create userRef clientRef and habRef
        this.userRef = this.formatValuesService.removeDuplicateValues(tmpUserRef).sort();
        this.clientRef = this.formatValuesService.removeDuplicateValues(tmpClientRef).sort();
        this.habilitationsRef = this.formatValuesService.removeDuplicateValues(tmpHabRef).sort();
        this.cols = this.getColsByUserRole();
    }


    getColsByUserRole() {
        if (this.authGuardService.isGranted('CAN_SEE_OWN_HABILITATIONS')) {
            return [
                {
                    field: 'formationDate',
                    title: 'Date',
                    dataType: 'date',
                    sort: true,
                    filterType: 'calendar',
                    filterValues: [],
                    class: 'width-20'
                },
                {
                    field: 'formation',
                    title: 'Habilitation',
                    dataType: 'string',
                    sort: true,
                    filterType: 'selectInput',
                    filterValues: this.formatValuesService.formatFilterValues(this.habilitationsRef),
                    class: 'width-25'
                },
                {
                    field: 'deadlineDate',
                    title: 'Echéance',
                    dataType: 'date',
                    sort: true,
                    filterType: 'calendar',
                    filterValues: [],
                    class: 'width-20'
                },
                {
                    field: 'status',
                    title: 'Statut',
                    dataType: 'statusIcon',
                    sort: true,
                    filterType: 'multiselect',
                    filterValues: this.formatValuesService.formatFilterValues(this.statusRef),
                    class: 'width-15'
                }];
        } else if (this.authGuardService.isGranted('CAN_SEE_CLIENT_HABILITATIONS')) {
            return [
                {
                    field: 'firstname',
                    title: 'Titulaire',
                    dataType: 'string',
                    sort: true,
                    filterType: 'selectInput',
                    filterValues: this.formatValuesService.formatFilterValues(this.userRef),
                    class: 'width-15'
                },
                {
                    field: 'formationDate',
                    title: 'Date',
                    dataType: 'date',
                    sort: true,
                    filterType: 'calendar',
                    filterValues: [],
                    class: 'width-15'
                },
                {
                    field: 'formation',
                    title: 'Habilitation',
                    dataType: 'string',
                    sort: true,
                    filterType: 'selectInput',
                    filterValues: this.formatValuesService.formatFilterValues(this.habilitationsRef),
                    class: 'width-20'
                },
                {
                    field: 'deadlineDate',
                    title: 'Echéance',
                    dataType: 'date',
                    sort: true,
                    filterType: 'calendar',
                    filterValues: [],
                    class: 'width-15'
                },
                {
                    field: 'status',
                    title: 'Statut',
                    dataType: 'statusIcon',
                    sort: true,
                    filterType: 'multiselect',
                    filterValues: this.formatValuesService.formatFilterValues(this.statusRef),
                    class: 'width-15'
                }];
        } else if (this.authGuardService.isGranted('CAN_SEE_ALL_HABILITATIONS')) {
            return [
                {
                    field: 'firstname',
                    title: 'Titulaire',
                    dataType: 'string',
                    sort: true,
                    filterType: 'selectInput',
                    filterValues: this.formatValuesService.formatFilterValues(this.userRef),
                    callback: CallbackCode.CALLBACK_HOLDER_HISTORY,
                    class: 'width-12'
                },
                {
                    field: 'client',
                    title: 'Société',
                    dataType: 'string',
                    sort: true,
                    filterType: 'selectInput',
                    filterValues: this.formatValuesService.formatFilterValues(this.clientRef),
                    class: 'width-10'
                },
                {
                    field: 'formationDate',
                    title: 'Date',
                    dataType: 'date',
                    sort: true,
                    filterType: 'calendar',
                    filterValues: [],
                    class: 'width-10'
                },
                {
                    field: 'formation',
                    title: 'Habilitation',
                    dataType: 'string',
                    sort: true,
                    filterType: 'selectInput',
                    filterValues: this.formatValuesService.formatFilterValues(this.habilitationsRef),
                    class: 'width-20'
                },
                {
                    field: 'relanceDate',
                    title: 'Relance',
                    dataType: 'date',
                    sort: true,
                    filterType: 'calendar',
                    filterValues: [],
                    class: 'width-10'
                },
                {
                    field: 'deadlineDate',
                    title: 'Echéance',
                    dataType: 'date',
                    sort: true,
                    filterType: 'calendar',
                    filterValues: [],
                    class: 'width-10'
                },
                {
                    field: 'status',
                    title: 'Statut',
                    dataType: 'statusIcon',
                    sort: true,
                    filterType: 'multiselect',
                    filterValues: this.formatValuesService.formatFilterValues(this.statusRef),
                    class: 'width-8'
                }
            ];
        }
    }

    onAction(_action: {actionType: string, id: number}) {
        switch (_action.actionType){
            case 'create':
                this.router.navigate(['/habilitation-create']);
                break;
            case 'edit':
                this.router.navigate(['/habilitation-detail/' + _action.id]);
                break;
            case 'pdf':
                this.store.selectHabilitations(_action.id).pipe(take(1)).subscribe(
                    hab => {
                        if (hab.exemptionPeriod) {
                            hab.deadlineDate = this.dateCalculatorService.dateAddDuration(hab.deadlineDate, hab.exemptionPeriod);
                        }
                        // get the rh client de la société and add the info to hab
                        hab.clientsRH = this.store.getRhClient(hab.holder.client.id);
                        this.pdfService.generateHabPdf(hab);
                    });

                break;
            case CallbackCode.CALLBACK_HOLDER_HISTORY:
                this.store.selectHabilitations(_action.id).pipe(take(1)).subscribe(
                    hab => {
                        this.showDialogService.showHolderHistoryDialog(hab.holder.id, CallbackCode.CALLBACK_HOLDER_HISTORY);
                    }
                );
                break;
        }
    }

    ngOnInit() {
        this.store.selectConfig('DELAI_RELANCE_RECYCLAGE').subscribe(
            recyclingDelay => {
                if (recyclingDelay) {
                    this.recyclingDelay = recyclingDelay.value;
                    if (this.habilitations.length > 0 && this.recyclingDelay !== ''){
                        this.getHabilitationListData();
                    }
                }
            }
        );
        this.store.selectHabilitations().subscribe(
            habilitations => {
                this.habilitations = habilitations;
                if (this.habilitations.length > 0 && this.recyclingDelay !== ''){
                    this.getHabilitationListData();
                }
            });

    }

    ionViewWillEnter(){
        if (this.authGuardService.isGranted('CAN_MANAGE_DATA')) {
            // Si l'utilisateur peut gérer les données il peut modifier une habilitation
            this.actionButtons = [
                {icon: 'pi pi-file-pdf', action: 'pdf'},
                {icon: 'pi pi-pencil', action: 'edit'}
            ];
            // Si l'utilisateur peut gérer les données il peut ajouter une habilitation
            this.newButtonLabel = 'Nouvelle habilitation';
        } else {
            this.newButtonLabel = '';
            // Utilisateur non gestionnaire, il ne peut que voir les données et le pdf d'une habilitation
            this.actionButtons = [
                {icon: 'pi pi-file-pdf', action: 'pdf'}
            ];
        }
        const fetchHabilitation: XtlAction = {
            type: ActionTypes.FETCH_HABILITATION,
            data: null
        };
        const fetchClient: XtlAction = {
            type: ActionTypes.FETCH_CLIENT,
            data: null
        };
        const fetchUser: XtlAction = {
            type: ActionTypes.FETCH_USER,
            data: null
        };
        const fetchConfig: XtlAction = {
            type: ActionTypes.FETCH_CONFIG,
            data: null
        };
        if ( this.store.getCurrentUser() !== null) {
            this.store.dispatch(fetchConfig);
            this.store.dispatch(fetchHabilitation);
            this.store.dispatch(fetchClient);
            this.store.dispatch(fetchUser);
            // set the current user role (to show custom message if client or RH client is connected and no habilitation are found)
            this.userRole = this.store.getCurrentUser().role;
        } else {
            this.router.navigate(['/login']);
        }
    }

    onDashFilter($event) {
        // Cas particulier 2 status doivent être cochés (EN_COURS_RENOUVELLEMENT + EN_COURS_RENOUVELLEMENT_DEROGATION)
        if ($event === StatusHabilitation.EN_COURS_RENOUVELLEMENT) {
            this.filterFromDash = [$event, StatusHabilitation.EN_COURS_RENOUVELLEMENT_DEROGATION];
            // Si on décoche via le dashboard
        } else if ($event === null) {
            this.filterFromDash = $event;
        } else {
            this.filterFromDash = [$event];
        }
    }

    onStatusFilter($event) {
        this.filterFromTable = $event;
    }
}
