import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-button-valid',
  templateUrl: './button-valid.component.html',
  styleUrls: ['./button-valid.component.scss'],
})
export class ButtonValidComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Input() type: string;
}
