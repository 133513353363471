import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  constructor(private http: HttpClient) { }
  public get(url: string): Observable<any> {
    console.log('network get')
    return this.http.get(url);
  }
  public post(url: string, payload: any): Observable<any> {
    return this.http.post(url, payload);
  }

  public put(url: string, payload: any): Observable<any> {
    return this.http.put(url, payload);
  }
}