// Liste des delais avant relance recyclage
export enum RecyclingDelay {
    ONE_MONTH = '1 MONTH',
    TWO_MONTH = '2 MONTH',
    THREE_MONTH = '3 MONTH',
    FOUR_MONTH = '4 MONTH',
    FIVE_MONTH = '5 MONTH',
    SIX_MONTH = '6 MONTH',
    SEVEN_MONTH = '7 MONTH',
    EIGHT_MONTH = '8 MONTH',
    NINE_MONTH = '9 MONTH',
    TEN_MONTH = '10 MONTH',
    ELEVEN_MONTH = '11 MONTH',
    TWELVE_MONTH = '12 MONTH'
}
