import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/service/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit{
  display: Boolean = false;
  loadingSubscription: Subscription;

  constructor(
    private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.isLoading$.pipe().subscribe(
      isLoading => {
        if (this.display !== isLoading){
          this.display = isLoading;
        }
      }
    );
  }


}
