import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-en-cours',
  templateUrl: './icon-en-cours.component.html',
  styleUrls: ['./icon-en-cours.component.scss'],
})
export class IconEnCoursComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
