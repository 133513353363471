// Liste des couleurs disponible pour les formations
export enum ColorFormation {
    RED = '#B3050D',
    GREEN = '#50533C',
    BLUE = '#332E54',
    PURPLE = '#660066',
    BEIGE = '#BF7F3F',
    CYAN = '#5991F5',
    GREY = '#91989C',
    ORANGE = '#F97701',
}
