import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {formatCurrency} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FormatValuesService {
  constructor( private translate: TranslateService) {}

  // transforme le tableau d'options, avec des doublons en tableau d'objects uniques avec les clefs valeurs label et value
  formatFilterValues(options: any[], needfirstEmptyValue?: boolean, firstLabel?: string) {
    const formatedOptions = [];
    if (needfirstEmptyValue) {
        formatedOptions.push({label: firstLabel, value: ''});
    }
    const tempOptions = [];
    options.forEach(option => {
      if ( tempOptions.indexOf(option) === -1 ) {
        tempOptions.push(option);
        if (typeof option === 'number') {
            // Les seuls nombres présents sont les prix des formations => formatage des nombres avec €
          formatedOptions.push(
              {
                label: formatCurrency(option, 'fr_FR', '€', 'EUR', '0.0-0'),
                value: option
              }
          );
        } else if (typeof option === 'boolean' || option === 'true' || option === 'false') {
            this.translate.get('BOOLEAN.' + option).subscribe((optionLabel: string) => {
                formatedOptions.push(
                    {
                        label: optionLabel,
                        value: option
                    }
                );
            });
        } else {
          this.translate.get(option).subscribe((optionLabel: string) => {
            formatedOptions.push(
                {
                  label: optionLabel,
                  value: option
                }
            );
          });
        }
      }
    });
    return formatedOptions;
  }

  // Supprime les doublons d'un tableau de valeur
  removeDuplicateValues(options: any[]) {
    const formatedOptions = [];
    const tempOptions = [];
    options.forEach(option => {
      if ( tempOptions.indexOf(option) === -1 ) {
        tempOptions.push(option);
        formatedOptions.push(option);
      }
    });
    return formatedOptions;
  }

  // Fonction de tri à utiliser dans un .sort() qui permet de trier un tableau d'objet sur une propriété des objets
  // Exemple usage :
  // users.sort(dynamicSort('firstname'));
  // users.sort(dynamicSort('-firstname'));
  dynamicSort(property) {
      let sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      }
  }
}
