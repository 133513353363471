import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { XtlClient } from 'src/app/model/xtl-client';
import { StoreService } from 'src/app/service/store.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import {FormatValuesService} from '../../service/format-values.service';
import {ReminderPeriodicityClient} from '../../enums/reminder-periodicity-client.enum';
import {Countries} from '../../enums/countries.enum';
import { take } from 'rxjs/operators';
import {FormValidatorService} from '../../service/form-validator.service';

@Component({
  selector: 'app-page-client-detail',
  templateUrl: './page-client-detail.component.html',
  styleUrls: ['./page-client-detail.component.scss'],
})
export class PageClientDetailComponent implements OnInit {

  clientForm: FormGroup;
  client: XtlClient;
  submitted = false;
  itemId: string;
  showConfirmDialog: boolean = false;

  // lists for form select
  periodicityOptions: {label: string, value: string}[];
  countryOptions: {label: string, value: string}[] = [];
  additionnalInfoOptions: {label: string, value: string}[] = [];

  constructor(
      private formBuilder: FormBuilder,
      public store: StoreService,
      private activatedRoute: ActivatedRoute,
      private location: Location,
      private formatValuesService: FormatValuesService,
      public formValidator: FormValidatorService
  ) {
  }

  ionViewWillEnter(){
    this.itemId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.itemId)
    {
      this.getSelectedItem();
      this.fetchUsers();
    } else {
      this.getDefaultReminderPeriodicity();
    }
  }

  ngOnInit() {
    this.clientForm = this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      zipcode: ['', Validators.required],
      country: ['FR', Validators.required],
      segment: [''],
      additionalInfo: [''],
      reminderPeriodicity: [''],
      active: [true],
    });

    this.periodicityOptions = this.formatValuesService.formatFilterValues(Object.values(ReminderPeriodicityClient));
    const countryList = Object.keys(Countries);
    countryList.forEach(key => {
      this.countryOptions.push({label: key, value: Countries[key]});
    });
  }

  get f() { return this.clientForm.controls; }

  onConfirm(){
    // Traitement de la desactivation client qui entraine la desactivation des users attachés
    this.showConfirmDialog = false;
    let userToUpdate = [];
    this.store.selectUsers().pipe(take(1)).subscribe(
      users => {
        users.forEach(user => {
          if (user.client.id == this.itemId) {
            user.active = false;
            user.client = '/api/clients/' + user.client.id;
            userToUpdate.push(user);
          }
        });
        let actionUsers: XtlAction;
        actionUsers = {
          type: ActionTypes.GROUPED_UPDATE_USER,
          data: userToUpdate
        };
        this.store.dispatch(actionUsers);
        let actionClients: XtlAction;
        actionClients = {
          type: ActionTypes.UPDATE_CLIENT,
          data: Object.assign(this.clientForm.value, {id: this.itemId})
        };
        this.store.dispatch(actionClients);
        this.location.back();
      }
    );
  }

  onSubmit() {
    this.submitted = true;

    if (this.clientForm.invalid) {
      return;
    }
    let action: XtlAction;

    if (this.itemId){
         // Traitement de la desactivation client qui entraine la desactivation des users attachés
        if (this.clientForm.get('active').value === false){
          this.showConfirmDialog = true;
        } else {
          action = {
            type: ActionTypes.UPDATE_CLIENT,
            data: Object.assign(this.clientForm.value, {id: this.itemId})
          };
          this.store.dispatch(action);
          this.location.back();
        }
      } else {
        action = {
          type: ActionTypes.ADD_CLIENT,
          data: Object.assign(this.clientForm.value, {id: null})
        };
        this.store.dispatch(action);
        this.location.back();
    }
  }

  onCancel() {
    this.location.back();
  }

  getSelectedItem(){
    this.store.selectClients(this.itemId).subscribe(
      client => {
        if (client) {
          this.client = client;
          this.clientForm.controls.name.setValue(this.client.name);
          this.clientForm.controls.address.setValue(this.client.address);
          this.clientForm.controls.city.setValue(this.client.city);
          this.clientForm.controls.zipcode.setValue(this.client.zipcode);
          this.clientForm.controls.country.setValue(this.client.country);
          this.clientForm.controls.segment.setValue(this.client.segment);
          this.clientForm.controls.additionalInfo.setValue(this.client.additionalInfo);
          this.clientForm.controls.reminderPeriodicity.setValue(this.client.reminderPeriodicity);
          this.clientForm.controls.active.setValue(this.client.active);
        } else if (this.store.getCurrentUser() != null){
          const action: XtlAction = {
            type: ActionTypes.FETCH_CLIENT,
            data: null
          };
          this.store.dispatch(action);
        }
      });
    }

  fetchUsers(){
    const action: XtlAction = {
      type: ActionTypes.FETCH_USER,
      data: null
    };
    this.store.dispatch(action);
  }

  getDefaultReminderPeriodicity() {
    this.store.selectConfig('PERIODICITE_RELANCE').subscribe(
        reminderPeriodicityClient => {
          if (reminderPeriodicityClient) {
            this.clientForm.controls.reminderPeriodicity.setValue(reminderPeriodicityClient.value);
          } else if (this.store.getCurrentUser() != null){
          const action: XtlAction = {
            type: ActionTypes.FETCH_CONFIG,
            data: null
          };
          this.store.dispatch(action);
          }
        }
    );
  }

  getAdditionnalInfos(event?) {
    this.store.selectClients().subscribe(
        clients => {
          if (clients.length !== 0) {
            const additionnalInfoList = [];
            clients.forEach(client => {
              if (event) {
                if (client.additionalInfo.toLowerCase().includes(event.query.toLowerCase())) {
                  additionnalInfoList.push(client.additionalInfo);
                }
              } else {
                additionnalInfoList.push(client.additionalInfo);
              }
            });
            this.additionnalInfoOptions = this.formatValuesService.removeDuplicateValues(additionnalInfoList);
          }
        }
    );
  }
}
