import { Injectable } from '@angular/core';
import { XtlReducer } from '../interfaces/xtl-reducer';
import { XtlUser, userListSchema, userSchema } from '../model/xtl-user';
import { environment } from 'src/environments/environment';
import {map, catchError, tap} from 'rxjs/operators';

import { NetworkService } from './network.service';
import { normalize } from 'normalizr';
import {forkJoin, Observable, of} from 'rxjs';
import {XtlMsgService} from './xtl-msg.service';


@Injectable({
  providedIn: 'root'
})
export class UserService implements XtlReducer<XtlUser>{
  constructor(
      private networkService: NetworkService,
      private xtlMsgService: XtlMsgService
  ) { }
  create(data: Partial<XtlUser>): Observable<any | { error: string; }> {
    data.createdAt = new Date();

    return this.networkService
      .post(environment.apiUrl + '/users', data)
      .pipe(
        tap(result => {
          this.xtlMsgService.add({
            severity: 'success',
            summary: 'Compte utilisateur créé',
            detail: 'Le compte utilisateur <b>' + data.firstname + ' ' + data.lastname + '</b> a été correctement créé.',
            life: 5000
          });
        }),
        map(result => {
          if (result.length !== 0) {
            return normalize(result, userSchema);
          } else {
            return { entities: { users: {} }, result: Array };
          }
        }),
        catchError((err, caught) => {
          this.xtlMsgService.add({
            severity: 'error',
            summary: 'Erreur de création compte utilisateur',
            detail: 'La création du compte utilisateur a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }
  update(data: Partial<XtlUser>): Observable<any | { error: string; }> {
    data.updatedAt = new Date();

    return this.networkService
      .put(environment.apiUrl + '/users/' + data.id, data)
      .pipe(
        tap(result => {
          this.xtlMsgService.add({
            severity: 'success',
            summary: 'Compte utilisateur modifié',
            detail: 'le compte utilisateur <b>' + data.firstname + ' ' + data.lastname + '</b> a été correctement modifié.',
            life: 10000
          });
        }),
        map(result => normalize(result, userSchema)),
        catchError((err, caught) => {
          this.xtlMsgService.add({
            severity: 'error',
            summary: 'Erreur de modification compte utilisateur',
            detail: 'La modification du compte utilisateur a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 10000
          });
          return [];
        })
      );
  }

  // group update basé sur le group update de client service
  groupUpdate(data: Partial<XtlUser>[]): Observable<any> {
    // les Observables renvoyés par put
    let updatedObs = [];

    data.forEach(user => {
      user.updatedAt = new Date();
      updatedObs.push(
          this.networkService
              .put(environment.apiUrl + '/users/' + user.id, user)
              .pipe(
                  map(result => Object.assign({isError: false}, normalize(result, userSchema))),
                  catchError(err => of({isError: true, error: err, name: user.firstname + ' ' + user.lastname})),
              )
      );
    });
    // traitement des messages: forkJoin attend le retour des Obs qu'on lui passe, tout passe par result,
      // y compris les erreur car elles sont catchés par les Obs fournis
    forkJoin<{isError: boolean, error?: string, entities?: {}, result?: number, name?: string}>(updatedObs).subscribe(
        results => {
          let errorCount = 0;
          let successCount = 0;
          results.forEach(
              result => {
                // un message par erreur
                if (result.isError){
                  errorCount++;
                  this.xtlMsgService.add({
                    severity: 'error',
                    summary: 'Erreur de modification compte utilisateur',
                    detail: 'La modification du compte utilisateur a échouée, si le problème persiste merci de contacter un administrateur.',
                    life: 20000
                  });
                } else {
                  successCount++;
                }
              }
          );
          // si des erreur et des succes, un seul message de succes
          if (successCount > 0 && errorCount > 0) {
            this.xtlMsgService.add({
              severity: 'success',
              summary: 'Comptes utilisateurs modifiés',
              detail: 'les autres comptes utilisateurs ont été correctement désactivés.',
              life: 5000
            });
            // si pas d'erreur et des succes, un seul message de succes
          } else if (successCount > 0 && errorCount === 0) {
            this.xtlMsgService.add({
              severity: 'success',
              summary: 'Comptes utilisateurs modifiés',
              detail: 'les comptes utilisateurs ont été correctement désactivés.',
              life: 5000,
                sticky: true,
            });
          }

        }

    );
    // forkJoin attend le retour des Obs qu'on lui passe, puis emet un tableau des valeur retournées par les Obs
    return forkJoin(updatedObs);

  }

  remove(target: XtlUser): Observable<true | { error: string; }> {
    throw new Error('Method not implemented.');
  }
  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl + '/users')
    .pipe(
      map(result => normalize(result, userListSchema)),
      catchError((err, caught) => {
        this.xtlMsgService.add({
          severity: 'error',
          summary: 'Erreur de récupération des comptes utilisateurs',
          detail: 'Le chargement des comptes utilisateurs a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 10000
        });
        return [];
      })
    );
  }
  get(id: number): Observable<any | { error: string; }> {
    return this.networkService
        .get(environment.apiUrl + '/users/' + id)
        .pipe(
            map(result => normalize(result, userSchema))
        );
  }
}
