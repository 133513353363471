import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import { SharedModule } from '../shared/shared.module';

import { PageBaseComponent } from './page-base/page-base.component';
import {PageClientListComponent} from './page-client-list/page-client-list.component';
import {PageClientDetailComponent} from './page-client-detail/page-client-detail.component';
import {PageConfigComponent} from './page-config/page-config.component';
import {PageEmailListComponent} from './page-email-list/page-email-list.component';
import {PageEmailDetailComponent} from './page-email-detail/page-email-detail.component';
import {PageFormateurListComponent} from './page-formateur-list/page-formateur-list.component';
import {PageFormateurDetailComponent} from './page-formateur-detail/page-formateur-detail.component';
import {PageFormationListComponent} from './page-formation-list/page-formation-list.component';
import {PageFormationDetailComponent} from './page-formation-detail/page-formation-detail.component';
import {PageHabilitationDetailComponent} from './page-habilitation-detail/page-habilitation-detail.component';
import {PageHabilitationListComponent} from './page-habilitation-list/page-habilitation-list.component';
import {PageLoginComponent} from './page-login/page-login.component';
import {PageUserListComponent} from './page-user-list/page-user-list.component';
import {PageUserDetailComponent} from './page-user-detail/page-user-detail.component';
import {PageActivateAccountComponent} from './page-activate-account/page-activate-account.component';
import {PageResetPasswordComponent} from './page-reset-password/page-reset-password.component';
import {PageChangePasswordComponent} from './page-change-password/page-change-password.component';

import {UiModule} from '../ui/ui.module';



@NgModule({
  declarations: [
      PageBaseComponent,
      PageClientListComponent, PageClientDetailComponent,
      PageConfigComponent,
      PageEmailListComponent, PageEmailDetailComponent,
      PageFormateurListComponent, PageFormateurDetailComponent,
      PageFormationListComponent, PageFormationDetailComponent,
      PageHabilitationListComponent, PageHabilitationDetailComponent,
      PageLoginComponent, PageActivateAccountComponent, PageResetPasswordComponent, PageChangePasswordComponent,
      PageUserListComponent, PageUserDetailComponent
  ],
    imports: [
        CommonModule,
        IonicModule,
        UiModule,
        ReactiveFormsModule,
        RouterModule,
        BrowserModule,
        SharedModule,
        FormsModule
    ],
  exports: [
    PageClientListComponent, PageClientDetailComponent,
    PageConfigComponent,
    PageEmailListComponent, PageEmailDetailComponent,
    PageFormateurListComponent, PageFormateurDetailComponent,
    PageFormationListComponent, PageFormationDetailComponent,
    PageHabilitationListComponent, PageHabilitationDetailComponent,
    PageLoginComponent, PageActivateAccountComponent, PageResetPasswordComponent, PageChangePasswordComponent,
    PageUserListComponent, PageUserDetailComponent, UiModule
  ]
})
export class PageModule { }
