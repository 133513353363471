import {Injectable} from '@angular/core';
import {XtlStore} from '../interfaces/xtl-store';
import {XtlState} from '../interfaces/xtl-state';
import {XtlAction} from '../interfaces/xtl-action';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ActionTypes} from '../enums/action-types.enum';
import {map, distinctUntilChanged} from 'rxjs/operators';
import {habilitationListSchema, habilitationSchema} from '../model/xtl-habilitation'
import {userListSchema, userSchema, XtlUser} from '../model/xtl-user';
import {denormalize} from 'normalizr';
import {HabilitationService} from './habilitation.service';
import {Storage} from '@ionic/storage';
import {AuthService} from './auth.service';
import {FormationService} from './formation.service';
import {formationListSchema, formationSchema} from '../model/xtl-formation';
import {UserService} from './user.service';
import {ClientService} from './client.service';
import {FormateurService} from './formateur.service';
import {formateurListSchema, formateurSchema} from '../model/xtl-formateur';
import {clientListSchema, clientSchema} from '../model/xtl-client';
import {emailListSchema, emailSchema} from '../model/xtl-email';
import {EmailService} from './email.service';
import {ConfigService} from './config.service';

@Injectable({
    providedIn: 'root'
})
export class StoreService implements XtlStore<XtlState>{



    private _actionHistory = [];

    private _initialState =
        <XtlState><unknown>{
            currentUser: null,
            selectedItem: {},
            habilitations: { entities: { habilitations: {} }, result: Array },
            formations: { entities: { formations: {} }, result: Array },
            formateurs: { entities: { formateurs: {} }, result: Array },
            users: {entities: { users: {} }, result: Array},
            clients: {entities: { clients: {} }, result: Array},
            emails: {entities: { emails: {} }, result: Array},
            config: [],
            error: []
        }

    private _state = this._initialState;


    constructor(
        private userService: UserService,
        private clientService: ClientService,
        private habilitationService: HabilitationService,
        private formationService: FormationService,
        private formateurService: FormateurService,
        private emailService: EmailService,
        private configService: ConfigService,
        private storage: Storage,
        private authService: AuthService,
    ) { }

    private logHistory(action:XtlAction){
        const archivedAction = Object.assign({time:Date.now()},action)
        this._actionHistory.push(archivedAction)
        console.groupCollapsed('Action Log');
        console.warn(archivedAction)
        console.groupCollapsed('Action History');
        console.table(this._actionHistory)
        console.groupEnd();
        console.groupEnd();

    }
    state$ = new BehaviorSubject<XtlState>(this._state);

    selectHabilitationsRef() {
        return this.state$.pipe(map(state => denormalize(Object.keys(state.formations.entities.formations), formationListSchema, state.formations.entities)));
    }

    selectHabilitations(id?) {
        if(id){
            return this.state$.pipe(
                map(state => denormalize(state.habilitations.entities.habilitations[id], habilitationSchema, state.habilitations.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x)
                }),
            );
        } else {
            return this.state$.pipe(
                map(state => denormalize(Object.keys(state.habilitations.entities.habilitations), habilitationListSchema, state.habilitations.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x)
                }),
            );
        }
    }

    selectFormateurs(id?) {
        if(id){
            return this.state$.pipe(map(state => denormalize(state.formateurs.entities.formateurs[id], formateurSchema, state.formateurs.entities)));

        } else {
            return this.state$.pipe(
                map(state => {
                    if (state.formateurs) {
                        return denormalize(Object.keys(state.formateurs.entities.formateurs), formateurListSchema, state.formateurs.entities)
                    }
                }),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x)
                }),
            );
        }
    }

    selectFormations(id?) {
        if (id){
            return this.state$.pipe(map(state => denormalize(state.formations.entities.formations[id], formationSchema, state.formations.entities)));
        } else {
            return this.state$.pipe(
                map(state => {
                    if (state.formations) {
                        return denormalize(Object.keys(state.formations.entities.formations), formationListSchema, state.formations.entities);
                    }
                }),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x);
                }),
            );
        }
    }

    selectUsers(id?) {
        if(id){
            return this.state$.pipe(map(state => denormalize(state.users.entities.users[id], userSchema, state.users.entities)));

        } else {
            return this.state$.pipe(
                map(state => denormalize(Object.keys(state.users.entities.users), userListSchema, state.users.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x)
                }),
            );

        }
    }

    selectClients(id?) {
        if (id){
            return this.state$.pipe(map(state => denormalize(state.clients.entities.clients[id], clientSchema, state.clients.entities)));

        } else {
            return this.state$.pipe(
                map(state => denormalize(Object.keys(state.clients.entities.clients), clientListSchema, state.clients.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x)
                }),
            );
        }
    }

    selectEmails(id?) {
        if(id){
            return this.state$.pipe(map(state => denormalize(state.emails.entities.emails[id], emailSchema, state.emails.entities)));

        } else {
            return this.state$.pipe(
                map(state => denormalize(Object.keys(state.emails.entities.emails), emailListSchema, state.emails.entities)),
                distinctUntilChanged((x, y) => {
                    return JSON.stringify(y) === JSON.stringify(x)
                }),
            );
        }
    }

    selectConfig(code?):Observable<any> {
        if(code){
            return this.state$.pipe(map(state => state.config.filter(conf=>conf.code == code)[0]))
        } else {
            return this.state$.pipe(map(state => state.config))
        }
    }

    selectSelectedItem() {
        return this.state$.pipe(map(state => state.selectedItem))
    }

    getCurrentUser() {
        return this._state.currentUser;
    }

    // get the rh client from client Id
    getRhClient(clientId) {
        let rhClients: Array<XtlUser> = [];
        Object.keys(this._state.users.entities.users).forEach(key => {
            let user = this._state.users.entities.users[key];
            if (user.role === 'ROLE_RH_CLIENT' && user.client === clientId && user.active) {
                rhClients.push(user);
            }
        });
        return rhClients;
    }

    refreshUser(User: Partial<XtlUser>) {
        this.state$.next(this._state = Object.assign({}, this._state, {
            currentUser: User,
            error: null
        }))
    }


    dispatch(action: XtlAction): void {
        console.log(action);
        this.logHistory(action)

        switch (action.type) {
            case ActionTypes.APP_INIT:

                break;

            case ActionTypes.SELECT_ITEM:
                this.state$.next(this._state = Object.assign({},this._state, {
                    selectedItem:{id: action.data.id, type:action.data.actionType}
                }));
                break;

            case ActionTypes.FETCH_HABILITATION:
                this.habilitationService.fetch().subscribe(
                    result => {
                        //Remplacement complet de l'element du store puisqu'on recupere l'ensemble des données du server
                        this.state$.next(this._state = Object.assign({},this._state, {habilitations:result} ));
                    }
                )
                break;

            case ActionTypes.ADD_HABILITATION:
                this.habilitationService.create(action.data).subscribe(
                    result => {
                        //Merge de chaque entité de l'element du store (ici habilitations), avec l'entité correspondante du payload normalisé
                        Object.keys(this._state.habilitations.entities).map((key)=> {
                            this._state.habilitations.entities[key] = Object.assign(this._state.habilitations.entities[key],result.entities[key])
                        });

                        this.state$.next(this._state = Object.assign({},this._state));
                    }
                )
                break;

            case ActionTypes.UPDATE_HABILITATION:
                this.habilitationService.update(action.data).subscribe(
                    result => {
                        //Merge de chaque entité de l'element du store (ici habilitations), avec l'entité correspondante du payload normalisé
                        Object.keys(this._state.habilitations.entities).map((key)=> {
                            this._state.habilitations.entities[key] = Object.assign(this._state.habilitations.entities[key],result.entities[key])
                        });

                        this.state$.next(this._state = Object.assign({},this._state));
                    }
                )
                break;

            case ActionTypes.FETCH_FORMATION:
                this.formationService.fetch().subscribe(
                    result => {
                        this.state$.next(this._state = Object.assign({},this._state, {formations:result} ));
                    }
                )
                break;

            case ActionTypes.ADD_FORMATION:
                this.formationService.create(denormalize(action.data,formationSchema,this._state.formations.entities)).subscribe(
                    result => {
                        Object.keys(this._state.formations.entities).map((key)=> {
                            this._state.formations.entities[key] = Object.assign(this._state.formations.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state));
                    }
                )
                break;

            case ActionTypes.UPDATE_FORMATION:
                this.formationService.update(action.data).subscribe(
                    result => {
                        Object.keys(this._state.formations.entities).map((key)=> {
                            this._state.formations.entities[key] = Object.assign(this._state.formations.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state));
                    }
                )
                break;

            case ActionTypes.FETCH_FORMATEUR:
                this.formateurService.fetch().subscribe(
                    result => {
                        this.state$.next(this._state = Object.assign({},this._state, {formateurs:result} ));
                    }
                )
                break;

            case ActionTypes.ADD_FORMATEUR:
                this.formateurService.create(denormalize(action.data,formateurSchema,this._state.formateurs.entities)).subscribe(
                    result => {
                        Object.keys(this._state.formateurs.entities).map((key)=> {
                            this._state.formateurs.entities[key] = Object.assign(this._state.formateurs.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state ));
                    }
                )
                break;

            case ActionTypes.UPDATE_FORMATEUR:
                this.formateurService.update(action.data).subscribe(
                    result => {
                        Object.keys(this._state.formateurs.entities).map((key)=> {
                            this._state.formateurs.entities[key] = Object.assign(this._state.formateurs.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state));
                    }
                )
                break;

            case ActionTypes.FETCH_USER:
                this.userService.fetch().subscribe(
                    result => {
                        this.state$.next(this._state = Object.assign({},this._state, {users:result} ));
                    }
                )
                break;

            case ActionTypes.ADD_USER:
                this.userService.create(action.data).subscribe(
                    result => {
                        Object.keys(this._state.users.entities).map((key)=> {
                            this._state.users.entities[key] = Object.assign(this._state.users.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state ));
                    }
                )
                break;

            case ActionTypes.UPDATE_USER:
                this.userService.update(action.data).subscribe(
                    result => {
                        Object.keys(this._state.users.entities).map((key)=> {
                            this._state.users.entities[key] = Object.assign(this._state.users.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state ));
                    }
                )
                break;
            case ActionTypes.GROUPED_UPDATE_USER:
                this.userService.groupUpdate(action.data).subscribe(
                    result => {
                        if (result.length !== 0) {
                            result.forEach(element => {
                                // les utilisateurs correctement mis à jour coté back sont mis à jours dans le store
                                if (!element.isError){
                                    Object.keys(this._state.users.entities).map((key) => {
                                        this._state.users.entities[key] = Object.assign(this._state.users.entities[key], element.entities[key])
                                    });
                                    this.state$.next(this._state = Object.assign({}, this._state ));
                                }
                            });
                        }
                    }
                )
                break;

            case ActionTypes.FETCH_CLIENT:
                this.clientService.fetch().subscribe(
                    result => {
                        this.state$.next(this._state = Object.assign({},this._state, {clients:result} ));
                    }
                )
                break;

            case ActionTypes.ADD_CLIENT:
                this.clientService.create(denormalize(action.data,clientSchema,this._state.clients.entities)).subscribe(
                    result => {
                        Object.keys(this._state.clients.entities).map((key)=> {
                            this._state.clients.entities[key] = Object.assign(this._state.clients.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state ));
                    }
                )
                break;

            case ActionTypes.UPDATE_CLIENT:
                this.clientService.update(action.data).subscribe(
                    result => {
                        Object.keys(this._state.clients.entities).map((key)=> {
                            this._state.clients.entities[key] = Object.assign(this._state.clients.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state ));
                    }
                )
                break;

            case ActionTypes.GROUPED_UPDATE_CLIENT:
                this.clientService.groupUpdate(action.data).subscribe(
                    result => {
                        if(result.length != 0) {
                            result.forEach(element => {
                                // les clients correctement mis à jour coté back sont mis à jours dans le store
                                if(!element.isError){
                                    Object.keys(this._state.clients.entities).map((key)=> {
                                        this._state.clients.entities[key] = Object.assign(this._state.clients.entities[key],element.entities[key])
                                    });
                                    this.state$.next(this._state = Object.assign({},this._state ));
                                }
                            });
                        }
                    }
                )
                break;

            case ActionTypes.FETCH_EMAIL:
                this.emailService.fetch().subscribe(
                    result => {
                        this.state$.next(this._state = Object.assign({},this._state, {emails:result} ));
                    }
                )
                break;

            case ActionTypes.ADD_EMAIL:
                this.emailService.create(denormalize(action.data,emailSchema,this._state.emails.entities)).subscribe(
                    result => {
                        Object.keys(this._state.emails.entities).map((key)=> {
                            this._state.emails.entities[key] = Object.assign(this._state.emails.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state ));
                    }
                )
                break;

            case ActionTypes.UPDATE_EMAIL:
                this.emailService.update(action.data).subscribe(
                    result => {
                        Object.keys(this._state.emails.entities).map((key)=> {
                            this._state.emails.entities[key] = Object.assign(this._state.emails.entities[key],result.entities[key])
                        });
                        this.state$.next(this._state = Object.assign({},this._state ));
                    }
                )
                break;
            case ActionTypes.FETCH_CONFIG:
                this.configService.fetch().subscribe(
                    result => {
                        this.state$.next(this._state = Object.assign({},this._state, {config:result} ));
                    }
                )
                break;

            case ActionTypes.UPDATE_CONFIG:
                this.configService.update(action.data).subscribe(
                    result => {
                        this._state.config[action.data.id-1] = result
                        this.state$.next(this._state = Object.assign({},this._state ));
                    }
                )
                break;

            case ActionTypes.USER_LOGIN:
                this.authService.create(action.data).subscribe(
                    result => this.state$.next(this._state = Object.assign({}, this._state, {currentUser: result, error: null})),
                    error => this.state$.next(this._state = Object.assign({}, this._state, {error})),
                );
                break;

            case ActionTypes.USER_LOGOUT:
                this.authService.remove();
                this.state$.next(this._state = Object.assign({}, this._state, {currentUser: null}));
                break;
            default:
                break;

        }
    }


}
