import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateTagService {

  private focusElement;
  private editorElement;
  constructor() { }

  setFocusElement(focusElement) {
    this.focusElement = focusElement;
    this.editorElement = null;
  }
  setEditorElement(editorElement) {
    this.editorElement = editorElement;
    this.focusElement = null;
  }
  insertTag(tagName: string) {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount && sel.focusNode.parentElement.closest('p-editor')) {
        const range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(tagName));
        sel.removeAllRanges();
      } else if (this.focusElement && sel.focusNode.firstChild && sel.focusNode.firstChild.parentElement.id === 'mailSubject') {
        this.focusElement.nativeElement.focus();
        const startPos = this.focusElement.nativeElement.selectionStart;
        const value = this.focusElement.nativeElement.value;
        this.focusElement.nativeElement.value = value.substring(0, startPos) + tagName + value.substring(startPos, value.length);
        this.focusElement = null;
      }
    }
  }
}
