import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap, catchError} from 'rxjs/operators';
import {XtlUser} from '../model/xtl-user';
import {environment} from '../../environments/environment';
import {NetworkService} from './network.service';
import {XtlReducer} from '../interfaces/xtl-reducer';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import {XtlMsgService} from './xtl-msg.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements XtlReducer<XtlUser> {
  fetch(): Observable<any> {
    throw new Error("Method not implemented.");
  }
  update(data: Partial<XtlUser>): Observable<XtlUser | { error: string; }> {
    throw new Error("Method not implemented.");
  }

  constructor(
    private networkService: NetworkService,
    private xtlMsgService: XtlMsgService,
    private storage: Storage,
    private translate: TranslateService
    ) {}

  create(data: {email: string, password: string}): Observable<any | { error: string; }> {
    return this.networkService.post(environment.apiUrl + '/login_check', data).pipe(
        tap(result => {
            this.storage.clear();
            this.storage.set('jwtToken', result.token);
        }),
        tap(result => this.storage.set('userId', result.id)),
        map(result => this.responseToUserAndToken(result)),
    );
  }
  remove(): Observable<true | { error: string }> {
    this.storage.clear();
    return;
  }
  // Methode de changement du mot de passe utilisateur
  changePassword(data: {token: string, email: string, password: string}): Observable<any | {error: string; }> {
      return this.networkService.post(environment.apiUrl + '/change_password', data);
  }
  // Methode de demande de réinitialisation du mot de passe
  resetPassword(data: {email: string}): Observable<any | {error: string; }> {
      return this.networkService.post(environment.apiUrl + '/reset_password', data).pipe(
          tap( result => console.log(result))
      );
  }
  // Methode de demande de vérification de token de reset password
  checkResetPasswordToken(data: {token: string, email: string}): Observable<any | {error: string; }> {
    return this.networkService.get(environment.apiUrl + '/check_reset_token?token=' + data.token + '&email=' + data.email).pipe(
        tap( result => console.log(result))
    );
  }

  private responseToUserAndToken(result) {
    return {
        id: result.id,
        civility: result.civility,
        lastname: result.lastname,
        firstname: result.firstname,
        email: result.email,
        role: result.role,
    };
  }
}
