import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-button-cancel',
  templateUrl: './button-cancel.component.html',
  styleUrls: ['./button-cancel.component.scss'],
})
export class ButtonCancelComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() type: string;
}
