import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {XtlEmail} from 'src/app/model/xtl-email';
import {StoreService} from 'src/app/service/store.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {XtlAction} from 'src/app/interfaces/xtl-action';
import {ActionTypes} from 'src/app/enums/action-types.enum';
import {MailTags} from '../../enums/mail-tags.enum';
import {TemplateTagService} from '../../service/template-tag.service';

@Component({
    selector: 'app-page-email-detail',
    templateUrl: './page-email-detail.component.html',
    styleUrls: ['./page-email-detail.component.scss'],
})
export class PageEmailDetailComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        public store: StoreService,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private templateTagService: TemplateTagService
    ) {
    }

    get f() {
        return this.emailForm.controls;
    }

    emailForm: FormGroup;
    email: XtlEmail;
    submitted = false;
    itemId: string;
    tagsList: object[];

    // lists for form select
    tagOptions: { label: string, value: string }[];

    ionViewWillEnter() {
        this.itemId = this.activatedRoute.snapshot.paramMap.get('id');
        if (this.itemId) {
            this.getSelectedItem();
        }
    }

    ngOnInit() {
        this.tagsList = Object.keys(MailTags).map(key => new Object({key: key, value: MailTags[key]}));
        this.emailForm = this.formBuilder.group({
            mailSubject: ['', Validators.required],
            mailBody: ['', Validators.required]
        });

        this.tagOptions = [
            {label: 'M', value: 'M'},
            {label: 'Mme', value: 'Mme'},
        ];

    }

    onSubmit() {
        this.submitted = true;

        if (this.emailForm.invalid) {
            return;
        }
        let action: XtlAction = {} as XtlAction;

        if (this.itemId) {
            action = {
                type: ActionTypes.UPDATE_EMAIL,
                data: Object.assign(this.emailForm.value, {id: this.itemId})
            };
        } else {
            action = {
                type: ActionTypes.ADD_EMAIL,
                data: Object.assign(this.emailForm.value, {id: null})
            };
        }

        this.store.dispatch(action);
        this.location.back();
    }

    onCancel() {
        this.location.back();
    }

    getSelectedItem() {
        this.store.selectEmails(this.itemId).subscribe(
            element => {
                if(element) {
                    this.email = element;
                    this.emailForm.controls.mailSubject.setValue(this.email.mailSubject);
                    this.emailForm.controls.mailBody.setValue(this.email.mailBody);
                } else if(this.store.getCurrentUser()!=null){
                    const action: XtlAction = {
                        type: ActionTypes.FETCH_EMAIL,
                        data: null
                    };
                    this.store.dispatch(action);
                }
                
            });
    }

    insertTag(tagName) {
        this.templateTagService.insertTag(tagName);
    }
}
