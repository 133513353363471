import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  @Input() display: boolean;
  @Output() hideMenu = new EventEmitter<any>();
  public userIcon = 'assets/icon/user.png';

  onCloseMenuClick() {
    this.hideMenu.emit();
  }
}
