import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StatusHabilitation} from '../../enums/status-habilitation.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {

  @Input() dashDatas: any[];
  @Input() filterFromTable: string;
  @Output() filterTable = new EventEmitter();
  selectedFilter: number;
  statushabilitation = StatusHabilitation;

  ngOnChanges(){
    switch (this.filterFromTable) {
      case this.statushabilitation.EN_COURS:
        this.selectedFilter = 0
        this.filterTable.emit(this.statushabilitation.EN_COURS);
        break;
      case this.statushabilitation.ECHEANCE_IMMINENTE:
        this.selectedFilter = 1
        this.filterTable.emit(this.statushabilitation.ECHEANCE_IMMINENTE);
        break;
      case this.statushabilitation.EN_COURS_RENOUVELLEMENT:
        this.selectedFilter = 2
        this.filterTable.emit(this.statushabilitation.EN_COURS_RENOUVELLEMENT);
        break;
      case this.statushabilitation.NON_RENOUVELEE:
        this.selectedFilter = 3
        this.filterTable.emit(this.statushabilitation.NON_RENOUVELEE);
        break;
      default:
          this.selectedFilter = null;
        break;
    }
  }

  onDashBtnClick(filterValue, index) {
    // si la table est déjà filtrée sur le même filtre, supprimer le filtre de la table
    if (index === this.selectedFilter) {
      this.selectedFilter = null;
      this.filterTable.emit(null);
    } else {
      this.selectedFilter = index;
      this.filterTable.emit(filterValue);
    }
}}
