import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as jsPDF from 'jspdf';
import {PdfEncodedProviderService} from './pdf-encoded-provider.service';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor(
      private imageProvider: PdfEncodedProviderService,
      public datepipe: DatePipe
  ) {
  }
  // lib js http://raw.githack.com/MrRio/jsPDF/master/docs/index.html
  // example of document https://andrekelling.de/works/jspdf-template/
  // on github https://github.com/AndreKelling/jspdf-template

  public generateHabPdf(hab) {

    const doc = new jsPDF('p', 'mm', 'a4');
    doc.setProperties({
      title: 'Titre d\'habilitation ' + hab.formation.reference + ' - ' + hab.holder.lastname,
      creator: 'SDCEM Academy'
    });

    // set up pour utiliser une custom font https://sphilee.github.io/jsPDF-CustomFonts-support/
    doc.addFileToVFS('verdana.ttf', this.imageProvider.verdanaRegular);
    doc.addFont('verdana.ttf', 'Verdana', 'regular');

    doc.addFileToVFS('verdanab.ttf', this.imageProvider.verdanaBold);
    doc.addFont('verdanab.ttf', 'Verdana', 'bold');
    doc.setFont('Verdana', 'regular');

    const fontSizes = {
      TitleFontSize: 20,
      SubTitleFontSize: 15,
      NormalFontSize: 9,
      SmallFontSize: 11
    };

    // header part
    doc.addImage(this.imageProvider.headerHabilitation, 'JPEG', 0, -20, 210, 101);
    doc.setTextColor('#E30913');
    doc.setFontSize(fontSizes.TitleFontSize);
    doc.text('SDCEM ACADEMY', 45,66);

    doc.setFontType('regular');
    doc.setTextColor('#000000');
    doc.text('Titre d\'habilitation', 121, 66);

    // Habilitation header
    doc.setFillColor(hab.formation.color);
    doc.roundedRect(25, 92, 160, 25, 5, 5, 'F');

    doc.setFont('Verdana', 'bold');
    doc.setFontSize(fontSizes.TitleFontSize);
    doc.setTextColor('#FFFFFF');
    doc.text(hab.formation.reference, 105, 101, {align: 'center'});

    doc.setFont('Verdana', 'regular');
    doc.setFontSize(fontSizes.SubTitleFontSize);
    doc.setTextColor('#FFFFFF');
    doc.text(hab.formation.title, 105, 110, {align: 'center'});

    // partie Habilitation
    doc.setFont('Verdana', 'bold');
    doc.setFontSize(fontSizes.SubTitleFontSize);
    doc.setTextColor('#000000');
    doc.text('Habilitation', 58, 135, {align: 'center'});

    doc.setFillColor('#E30913');
    doc.rect(25, 141, 66, 0.5, 'F');

    doc.setFont('Verdana', 'bold');
    doc.setFontSize(fontSizes.NormalFontSize);
    doc.setTextColor('#91989C');
    doc.text('Référence', 25, 150);
    doc.text('Formateur', 25, 160);
    doc.text('Délivré le', 25, 170);
    doc.text('Date de validité', 25, 180);

    doc.setFont('Verdana', 'regular');
    doc.setFontSize(fontSizes.SmallFontSize);
    doc.setTextColor('#000000');
    doc.text(`${hab.reference}`, 46, 150);
    doc.text(`${hab.formateur.civility} ${hab.formateur.firstname} ${hab.formateur.lastname}`, 46, 160);
    doc.text(this.datepipe.transform(hab.formationDate, 'dd/MM/yyyy'), 45, 170);
    doc.text(this.datepipe.transform(hab.deadlineDate, 'dd/MM/yyyy'), 55, 180);

    // partie Titulaire
    doc.setFont('Verdana', 'bold');
    doc.setFontSize(fontSizes.SubTitleFontSize);
    doc.setTextColor('#000000');
    doc.text('Titulaire', 146, 135, {align: 'center'});

    doc.setFillColor('#E30913');
    doc.rect(120, 141, 52, 0.5, 'F');

    doc.setFont('Verdana', 'bold');
    doc.setFontSize(fontSizes.NormalFontSize);
    doc.setTextColor('#91989C');
    doc.text('Nom', 120, 150);
    doc.text('Prénom', 120, 160);

    doc.setFont('Verdana', 'regular');
    doc.setFontSize(fontSizes.SmallFontSize);
    doc.setTextColor('#000000');
    doc.text(hab.holder.lastname, 132, 150);
    doc.text(hab.holder.firstname, 138, 160);

    // partie Société
    doc.setFont('Verdana', 'bold');
    doc.setFontSize(fontSizes.SubTitleFontSize);
    doc.setTextColor('#000000');
    doc.text('Société du titulaire', 105, 195, {align: 'center'});

    doc.setFillColor('#E30913');
    doc.rect(37, 201, 135, 0.5, 'F');

    doc.setFont('Verdana', 'bold');
    doc.setFontSize(fontSizes.NormalFontSize);
    doc.setTextColor('#91989C');
    doc.text('Nom de la société', 25, 210);

    doc.setFont('Verdana', 'regular');
    doc.setFontSize(fontSizes.SmallFontSize);
    doc.setTextColor('#000000');
    doc.text(hab.holder.client.name, 60, 210);
    let startingYCoord = 230;
    // if there is RH for the client, show them
    if (hab.clientsRH.length > 0) {
      doc.setFont('Verdana', 'bold');
      doc.setFontSize(fontSizes.NormalFontSize);
      doc.setTextColor('#91989C');
      doc.text('Contact RH :', 25, 220);
      for (const clientRH of hab.clientsRH) {
        // titles
        doc.setFont('Verdana', 'bold');
        doc.setFontSize(fontSizes.NormalFontSize);
        doc.setTextColor('#91989C');
        doc.text('Nom', 25, startingYCoord);
        doc.text('Prénom', 120, startingYCoord);
        doc.text('Email', 25, startingYCoord + 10);
        doc.text('Télephone', 120, startingYCoord + 10);
        // values
        doc.setFont('Verdana', 'regular');
        doc.setFontSize(fontSizes.SmallFontSize);
        doc.setTextColor('#000000');
        doc.text(clientRH.lastname, 36, startingYCoord);
        doc.text(clientRH.firstname, 137, startingYCoord);
        doc.text(clientRH.email, 38, startingYCoord + 10);
        doc.text(clientRH.phoneNumber, 141, startingYCoord + 10);
        // add 20 to the satringYCoords for the next RH client
        startingYCoord = startingYCoord + 20;
      }
    }

    // partie Footer
    doc.setFillColor('#91989C');
    doc.rect(0, 274, 210, 0.5, 'F');
    doc.addImage(this.imageProvider.sdcemLogo, 'JPEG', 30, 282, 37, 9);
    doc.setFontSize(9);
    doc.text('Le titre d\'habilitation est délivré par SDCEM Academy.', 82, 284);
    doc.text('Il est strictement personnel et ne peut être remis à un tiers.', 82, 288);

    // https://stackoverflow.com/questions/40657964/how-to-display-a-javascript-file-object-in-chromes-pdf-viewer-with-its-name
    // nom random qui est généré
    // window.open(doc.output('bloburl', {filename: 'Titre d\'habilitation ' + hab.formation.reference + ' - ' + hab.holder.lastname}), 'Titre d\'habilitation ' + hab.formation.reference + ' - ' + hab.holder.lastname);
    // solution initiale
    // doc.output('dataurlnewwindow', {filename: 'Titre d\'habilitation ' + hab.formation.reference + ' - ' + hab.holder.lastname});
    window.open(doc.output("bloburl", 'filename.pdf'));
  }
}
