import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { formatDate} from '@angular/common';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {

  @Input() placeholder: string;
  @Input() styleClass: string;
  @Input() parentForm: FormGroup;
  @Input() parentFormControlName: string;
  @Input() defaultFilter: string;
  @Input() view: string;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onClearClick = new EventEmitter<any>();

  formControlName: string;
  fr: any;
  maxYearRange: number;
  selectedValue: Date;

  ngOnInit(): void {
    this.formControlName = this.parentFormControlName;
    this.fr = {
      firstDayOfWeek: 1,
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['D', 'L', 'Ma', 'Me', 'J', 'V', 'S'],
      monthNames: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre' ],
      monthNamesShort: [ 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Au', 'Sep', 'Oct', 'Nov', 'Dec' ],
      today: this.view === 'month' ? 'Mois en cours' : 'Aujourd\'hui',
      clear: 'Vider',
      dateFormat: 'dd/mm/yy',
      weekHeader: ''
    };
    // set the max year of range year dropdown to this year + 5 years
    this.maxYearRange = new Date().getFullYear() + 5;
  }

  ngOnChanges() {
    // set la valeur du multiselect input
    if (this.defaultFilter !== undefined) {
      if (this.defaultFilter === null) {
        this.selectedValue = null;
      } else {
        this.selectedValue = new Date(this.defaultFilter);
      }
    }
  }

  handleSelectFullDate(value) {
    this.onSelect.emit(value);
  }
  handleSelectMonth(value) {
    this.onSelect.emit(value);
  }
  handleClearClick() {
    this.onClearClick.emit();
  }
}
