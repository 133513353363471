import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/service/store.service';
import { Router } from '@angular/router';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import {FormatValuesService} from '../../service/format-values.service';
import {Active} from '../../enums/active.enum';
import {ReminderPeriodicityClient} from '../../enums/reminder-periodicity-client.enum';
import { take, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-page-client-list',
    templateUrl: './page-client-list.component.html',
    styleUrls: ['./page-client-list.component.scss'],
})
export class PageClientListComponent implements OnInit {

    clientsTableDatas: any[];
    cols: any[];
    actionButtons = [
        {icon: 'pi pi-pencil', action: 'edit'}
    ];

    groupedActionButton = {label: 'Rétablir la périodicité par défaut', action: 'défaultPeriodicity'};

    clientNameRef: any[];
    cityRef: any[];
    reminderPeriodicityRef: any[];
    activeRef: any[];
    defaultPeriodicity: any;
    showConfirmDialog: boolean = false;
    selectedClient: Array<any> = [];
    defaultFilter: Array<{ value: string, field: any, matchMode: string }>;

    constructor(
        public store: StoreService,
        private router: Router,
        private formatValuesService: FormatValuesService
    ) { }

    ngOnInit() {
        this.defaultFilter = [{ value: 'true', field: 'active', matchMode: 'equals' }];
        this.getClientListData();
        this.getDefaultReminderPeriodicity();
    }

    getDefaultReminderPeriodicity() {
        this.store.selectConfig('PERIODICITE_RELANCE').pipe(debounceTime(500)).subscribe(
            reminderPeriodicityClient => {
              if (reminderPeriodicityClient) {
                this.defaultPeriodicity = reminderPeriodicityClient;
              } else if (this.store.getCurrentUser() != null){
              const action: XtlAction = {
                type: ActionTypes.FETCH_CONFIG,
                data: null
              };
              this.store.dispatch(action);
              }
            }
        );
    }

    getClientListData() {
        this.store.selectClients().subscribe(
            clients => {
                if (clients.length !== 0){
                    this.clientsTableDatas = [];
                    this.cityRef = [];
                    clients.forEach(client => {
                        const formatedClient = {
                            id: client.id,
                            name: client.name + ' - ' + client.additionalInfo,
                            city: client.city,
                            reminderPeriodicity: client.reminderPeriodicity,
                            active: client.active
                        };
                        this.clientsTableDatas.push(formatedClient);
                        this.cityRef.push(client.city);
                    });

                    this.clientNameRef = [];
                    this.activeRef = Object.values(Active);
                    this.reminderPeriodicityRef = Object.values(ReminderPeriodicityClient);
                    clients.forEach(client => {
                        this.clientNameRef.push(client.name);
                    });
                    this.cols = [
                    {
                        field: 'name',
                        title: 'Nom de la société',
                        dataType: 'string',
                        sort: true,
                        filterType: 'selectInput',
                        filterValues: this.formatValuesService.formatFilterValues(this.clientNameRef),
                        class: 'width-40'
                    },
                    {
                        field: 'city',
                        title: 'Ville',
                        dataType: 'string',
                        sort: true,
                        filterType: 'selectInput',
                        filterValues: this.formatValuesService.formatFilterValues(this.cityRef),
                        class: 'width-15'
                    },
                    {
                        field: 'reminderPeriodicity',
                        title: 'Périodicité relance',
                        dataType: 'string',
                        sort: true,
                        filterType: 'dropdown',
                        filterValues: this.formatValuesService.formatFilterValues(this.reminderPeriodicityRef),
                        class: 'width-15'
                    },
                    {
                        field: 'active',
                        title: 'Actif',
                        dataType: 'active',
                        sort: true,
                        filterType: 'dropdown',
                        defaultFilter: 'true',
                        filterValues: this.formatValuesService.formatFilterValues(this.activeRef),
                        class: 'width-10'
                    },
                    ];
                } else if(this.store.getCurrentUser() != null){
                    const action: XtlAction = {
                        type: ActionTypes.FETCH_CLIENT,
                        data: null
                    };
                    this.store.dispatch(action);
                }
            }
        );
    }

    onAction(_action: {actionType: string, id: number}) {
        switch (_action.actionType){
            case 'create':
                this.router.navigate(['/client-create']);
                break;
            case 'edit':
                this.router.navigate(['/client-detail/' + _action.id]);
                break;
        }
    }

    onConfirm(){
        let clientToUpdate = [];
        this.selectedClient.forEach(client => {
            this.store.selectClients(client.id).pipe(take(1)).subscribe(
                client => {
                    if (client.reminderPeriodicity != this.defaultPeriodicity.value) {
                        client.reminderPeriodicity = this.defaultPeriodicity.value;
                        client.id = client.id.toString();
                        delete client.users;
                        clientToUpdate.push(client);
                    }
                }
            );
        });
        let action: XtlAction;
        action = {
            type: ActionTypes.GROUPED_UPDATE_CLIENT,
            data: clientToUpdate
        };
        this.store.dispatch(action);
        this.showConfirmDialog = false;

    }

    onCancel(){
        this.showConfirmDialog = false;
    }


    onGroupedAction(_selectedData: Array<any>){
        this.selectedClient = _selectedData;
        this.showConfirmDialog = true;
    }

}
