import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthService} from './auth.service';
import {ClientService} from './client.service';
import {ConfigService} from './config.service';
import {EmailService} from './email.service';
import {FormateurService} from './formateur.service';
import {FormationService} from './formation.service';
import {HabilitationService} from './habilitation.service';
import {NetworkService} from './network.service';
import {UserService} from './user.service';
import {LoaderService} from './loader.service';
import {PdfService} from './pdf.service';
import {PdfEncodedProviderService} from './pdf-encoded-provider.service';
import {FormatValuesService} from './format-values.service';
import { MessageService } from 'primeng/api';
import {ShowDialogService} from './show-dialog.service';
import {DialogService} from 'primeng/dynamicdialog';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
      AuthService,
      ClientService,
      ConfigService,
      EmailService,
      FormateurService,
      MessageService,
      FormationService,
      HabilitationService,
      NetworkService,
      UserService,
      LoaderService,
      UserService,
      PdfService,
      PdfEncodedProviderService,
      FormatValuesService,
      ShowDialogService,
      DialogService
  ]
})
export class ServiceModule { }
