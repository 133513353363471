import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-non-renouvelee',
  templateUrl: './icon-non-renouvelee.component.html',
  styleUrls: ['./icon-non-renouvelee.component.scss'],
})
export class IconNonRenouveleeComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
