import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {PageLoginComponent} from './page/page-login/page-login.component';
import {PageConfigComponent} from './page/page-config/page-config.component';
import {PageHabilitationDetailComponent} from './page/page-habilitation-detail/page-habilitation-detail.component';
import {PageHabilitationListComponent} from './page/page-habilitation-list/page-habilitation-list.component';
import {PageClientDetailComponent} from './page/page-client-detail/page-client-detail.component';
import {PageClientListComponent} from './page/page-client-list/page-client-list.component';
import {PageUserListComponent} from './page/page-user-list/page-user-list.component';
import {PageUserDetailComponent} from './page/page-user-detail/page-user-detail.component';
import {PageFormationListComponent} from './page/page-formation-list/page-formation-list.component';
import {PageFormationDetailComponent} from './page/page-formation-detail/page-formation-detail.component';
import {PageFormateurListComponent} from './page/page-formateur-list/page-formateur-list.component';
import {PageFormateurDetailComponent} from './page/page-formateur-detail/page-formateur-detail.component';
import {PageEmailListComponent} from './page/page-email-list/page-email-list.component';
import {PageEmailDetailComponent} from './page/page-email-detail/page-email-detail.component';
import {PageActivateAccountComponent} from './page/page-activate-account/page-activate-account.component';
import {PageResetPasswordComponent} from './page/page-reset-password/page-reset-password.component';
import {PageChangePasswordComponent} from './page/page-change-password/page-change-password.component';
import {AuthGuardService} from './service/auth-guard.service';


const routes: Routes = [
  {
    path: 'login', component: PageLoginComponent
  },
  {
    path: 'activate-account', component: PageActivateAccountComponent
  },
  {
    path: 'reset-password', component: PageResetPasswordComponent
  },
  {
    path: 'change-password', component: PageChangePasswordComponent
  },
  {
    path: 'config', component: PageConfigComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'habilitation-list', component: PageHabilitationListComponent
  },
  {
    path: 'habilitation-create', component: PageHabilitationDetailComponent
  },
  {
    path: 'habilitation-detail/:id', component: PageHabilitationDetailComponent
  },
  {
    path: 'client-list', component: PageClientListComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'client-create', component: PageClientDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'client-detail/:id', component: PageClientDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'user-list', component: PageUserListComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'user-create', component: PageUserDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'user-detail/:id', component: PageUserDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'formation-list', component: PageFormationListComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'formation-create', component: PageFormationDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'formation-detail/:id', component: PageFormationDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'formateur-list', component: PageFormateurListComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'formateur-create', component: PageFormateurDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'formateur-detail/:id', component: PageFormateurDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'email-list', component: PageEmailListComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'email-create', component: PageEmailDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: 'email-detail/:id', component: PageEmailDetailComponent, canActivate: [AuthGuardService], data: { auth: 'CAN_MANAGE_DATA' }
  },
  {
    path: '',
    redirectTo: 'habilitation-list',
    pathMatch: 'full'
  },
  {
    path: '**' , redirectTo: 'habilitation-list'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
