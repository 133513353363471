import {Component, OnInit} from '@angular/core';
import {} from 'primeng/dynamicdialog';
import {XtlUser} from '../../model/xtl-user';
import {XtlHabilitation} from '../../model/xtl-habilitation';
import {StoreService} from '../../service/store.service';
import {XtlFormation} from '../../model/xtl-formation';
import {ShowDialogService} from '../../service/show-dialog.service';
import {CallbackCode} from '../../enums/callback-code.enum';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';

@Component({
  selector: 'app-holder-history-dialog',
  templateUrl: './holder-history-dialog.component.html',
  styleUrls: ['./holder-history-dialog.component.scss'],
})
export class HolderHistoryDialogComponent implements OnInit{

  holderId: number;
  holder: XtlUser;
  display: boolean;
  noHistory: boolean;
  holderHabilitationsByFormations: {formation: XtlFormation, habilitations: XtlHabilitation[]}[] = [];
  rhClients: Array<XtlUser>;
  constructor(private store: StoreService, private showDialogService: ShowDialogService) {
  }

    ngOnInit() {
      this.showDialogService.userId$.subscribe(
          userId => {
              this.holderId = userId;
          }
      );
      this.showDialogService.displayDialog$.subscribe(
          display => {
              this.display = display;
          }
      );
      this.showDialogService.callbackcode$.subscribe(
          callbackCode => {
              if (callbackCode === CallbackCode.CALLBACK_HOLDER_HISTORY) {
                  this.getHistory();
              }
          }
      );
  }

  getHistory() {
    this.holderHabilitationsByFormations = [];
    this.store.selectFormations().subscribe(
                formations => {
                    if (formations.length != 0) {
                        formations.forEach(formation => {
                            this.holderHabilitationsByFormations.push({formation, habilitations: []});
                        });
                        this.store.selectUsers(this.holderId).subscribe(
                            user => {
                                this.holder = user;
                                this.rhClients = this.store.getRhClient(this.holder.client.id);
                            }
                        );

                        this.store.selectHabilitations().subscribe(
                            habilitations => {
                                if (habilitations.length != 0) {
                                    this.noHistory = true;
                                    habilitations.forEach(habilitation => {
                                    if (habilitation.holder.id === this.holderId) {
                                        this.holderHabilitationsByFormations.find(formationsList => formationsList.formation.id === habilitation.formation.id).habilitations.push(habilitation);
                                    }
                                    this.holderHabilitationsByFormations.forEach(holderHabilitationsByFormation => {
                                        if (holderHabilitationsByFormation.habilitations.length != 0) {
                                            this.noHistory = false;
                                        }
                                    });
                                });
                                } else {
                                    const action: XtlAction = {
                                        type: ActionTypes.FETCH_HABILITATION,
                                        data: null
                                    };
                                    this.store.dispatch(action);
                                }
                            }
                        );
                    } else {
                        const action: XtlAction = {
                            type: ActionTypes.FETCH_FORMATION,
                            data: null
                        };
                        this.store.dispatch(action);
                    }
                }
            );
  }

  closeHolderHistoryDialog() {
      this.holderId = null;
      this.holder = null;
      this.holderHabilitationsByFormations = [];
      this.showDialogService.hideDialog();
  }
}
