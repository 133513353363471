import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {ActionTypes} from '../../enums/action-types.enum';
import {XtlAction} from '../../interfaces/xtl-action';
import { StoreService } from 'src/app/service/store.service';
import { XtlMsgService } from '../../service/xtl-msg.service';


@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;


    constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private xtlMsgService: XtlMsgService,
      private storeService: StoreService) {}

  ngOnInit(): void {
    this.storeService.state$.subscribe(result => this.afterAuth(result));
    this.initForm();
 }

  initForm() {
        this.loginForm = this.formBuilder.group({
            email: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,10}$')]],
            password: [null, Validators.required]
        });
  }

  get f() { return this.loginForm.controls; }

  onAuth() {
      this.submitted = true;
      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }
      const loginValues = this.loginForm.getRawValue();
      const action: XtlAction = {
          type: ActionTypes.USER_LOGIN,
          data: {username: loginValues.email, password: loginValues.password}
      };
      this.storeService.dispatch(action);
  }

  afterAuth(data): void {
      if (this.submitted) {
          if (data.currentUser) {
              this.xtlMsgService.clear();
              this.router.navigate(['/habilitation-list']);
          } else {
              this.router.navigate(['/login']);
          }
          this.submitted = false;
      }
  }
}
