import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-en-cours-renouvellement-derog',
  templateUrl: './icon-en-cours-renouvellement-derog.component.html',
  styleUrls: ['./icon-en-cours-renouvellement-derog.component.scss'],
})
export class IconEnCoursRenouvellementDerogComponent implements OnInit {

  @Input() exemptionPeriod: string;

  constructor() { }

  ngOnInit() {}

}
