import { Injectable } from '@angular/core';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  constructor() { }

  public hasRequiredValidator(form: FormGroup, fieldName: string): boolean {
    if (form.controls[fieldName].validator) {
      // returns true if control has the validator
      // @ts-ignore
      return !!form.controls[fieldName].validator(fieldName).required;
    }
    return false;
  }
}
