import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-select2',
  templateUrl: './select2.component.html',
  styleUrls: ['./select2.component.scss'],
})
export class Select2Component implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() parentFormControlName: string;
  @Input() options: any[];
  @Input() styleClass: string;
  @Input() placeholder: string;
  @Input() defaultFilter: string;
  @Output() onChange = new EventEmitter<any>();

  formControlName: string;
  selectedValue: string;

  ngOnChanges() {
    // set la valeur du multiselect input
    if (this.defaultFilter !== undefined) {
      if (this.defaultFilter === null) {
        this.selectedValue = null;
      } else {
        this.selectedValue = this.defaultFilter;
      }
    }
  }

  ngOnInit(): void {
    this.formControlName = this.parentFormControlName;
  }

  handleChange($event) {
    this.onChange.emit($event);
  }
}
