import {Component, Input, OnInit} from '@angular/core';
import {Message} from 'primeng/api';
import {LoaderService} from '../../service/loader.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {

  @Input() msgs: Message[];
  hideToasts: Boolean = true;

  constructor(
      private loaderService: LoaderService
  ) { }

  ngOnInit() {
    // hide toast already displayed when the loader appear
    this.loaderService.isLoading$.subscribe(
        isLoading => {
          this.hideToasts = isLoading;
        }
    );
  }

}
