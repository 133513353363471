import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-echeance-imminente',
  templateUrl: './icon-echeance-imminente.component.html',
  styleUrls: ['./icon-echeance-imminente.component.scss'],
})
export class IconEcheanceImminenteComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
