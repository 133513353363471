import {Component, ViewChild, Input, OnChanges, OnInit, Output, EventEmitter, SimpleChanges, OnDestroy, HostListener} from '@angular/core';
import {Table} from 'primeng/table';
import {ShowDialogService} from '../../service/show-dialog.service';
import {StatusHabilitation} from '../../enums/status-habilitation.enum';
import {Router} from '@angular/router';
import { Storage } from '@ionic/storage';
import {formatDate} from '@angular/common';
import { UserRole } from '../../enums/user-role.enum';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnChanges, OnDestroy {

    @Input() data: any[];
    @Input() cols: any[];
    @Input() newButtonLabel = '';
    @Input() groupedActionButton: {label: string, action: string};
    @Input() actionButtons: Array <object> = [];
    @Input() filterFromDash = [];
    @Input() defaultFilter: Array <{ value: string, field: any, matchMode: string }> = [];
    @Input() needCheckbox: boolean;
    @Input() userRole: string = null;
    @Output() action = new EventEmitter<{ actionType: string, id: number }>();
    @Output() groupedAction = new EventEmitter< Array<any>>();
    @Output() filtersToDash = new EventEmitter();

    @ViewChild('dt') table: Table;

    selectedData: Array<any> = [];
    loading = false;
    displayFilter = false;
    isTableFlitered = false;
    initialized = false;
    savedFilters: object = {};
    nbrOfRows = 0;
    UserRole = UserRole;

    constructor(public showDialogService: ShowDialogService, private router: Router, private storage: Storage) {
    }

    ngOnInit(){
        this.initialized = true;
        if (this.defaultFilter){
            this.defaultFilter.forEach(
                // si des filtres par defaut sont définis, on initialise savedFilter avec pour ne traiter que savedFilter par la suite
                filter => this.savedFilters[filter.field] = {label: filter.value, value: filter.value }
            );
        }
    }

    // hostlistener ecoute un event du dom (destroy non appelé sinon...)
    @HostListener('unload', ['$event'])
    ngOnDestroy(){
        // on vide les filtres (sur logout notament)
        console.log('TABLE RESET')
        this.table.reset();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filterFromDash){
            // application des filtres externes
            if (this.initialized && this.filterFromDash !== undefined) {
                this.table.filter(this.filterFromDash, 'status', 'in');
            }
        } else {
            this.storage.get(this.router.url).then(
                filters => {
                    if (filters){
                        // variable utilisé par le template pour mettre à jour les valeur de filtres
                        this.savedFilters = filters;
                        // application des filtres sauvegardés
                        Object.keys(filters).forEach(
                            filterKey => {
                                // si un filtre sauvegardé status existe,et qu'il est sur une valeur unique du dashboard on met le dashboard à jour
                                if (
                                    // tous les cas de dashboard actif sauf renouvellement
                                    (filterKey === 'status' && filters[filterKey].value.length === 1 &&
                                    (filters[filterKey].value[0] === StatusHabilitation.EN_COURS ||
                                    filters[filterKey].value[0] === StatusHabilitation.ECHEANCE_IMMINENTE ||
                                    filters[filterKey].value[0] === StatusHabilitation.NON_RENOUVELEE))
                                ){
                                    // et on ne filtre pas, c'est le dashboard qui le fera
                                    this.filtersToDash.emit(filters[filterKey].value[0]);
                                } else if (
                                    // cas particulier dashboard renouvellement
                                    (filterKey === 'status' && filters[filterKey].value.length === 2 &&
                                    (filters[filterKey].value[0] === StatusHabilitation.EN_COURS_RENOUVELLEMENT &&
                                    filters[filterKey].value[1] === StatusHabilitation.EN_COURS_RENOUVELLEMENT_DEROGATION)) ||

                                    (filterKey === 'status' && filters[filterKey].value.length === 2 &&
                                    (filters[filterKey].value[1] === StatusHabilitation.EN_COURS_RENOUVELLEMENT &&
                                    filters[filterKey].value[0] === StatusHabilitation.EN_COURS_RENOUVELLEMENT_DEROGATION))
                                ) {
                                    this.filtersToDash.emit(StatusHabilitation.EN_COURS_RENOUVELLEMENT);
                                } else {
                                    this.filtersToDash.emit(null);
                                    this.table.filter(filters[filterKey].value, filterKey, filters[filterKey].matchMode);
                                }
                            });

                    } else {
                        // si pas de filtres sauvegardés, on applique les filtres par défaut
                        if (this.initialized && this.defaultFilter !== undefined){
                            this.defaultFilter.forEach(
                                filter => this.table.filter(filter.value, filter.field, filter.matchMode)
                            );
                        }
                    }
                }
            );

        }
        // set the number of row elements
        if (this.data !== undefined && this.data.length > 0) {
            this.nbrOfRows = this.data.length;
        }
    }

    onFilter(event) {
        // save filters key = route de la page, value = filtres actifs
        this.storage.set(this.router.url, event.filters);

        // mise à jour du dash si changement manuel du filtre status
        // cas de combinaison de filtres statut qui correspondent à un element de dashboard
        if (this.table.filters.status && this.table.filters.status.value.length === 1 &&
            (this.table.filters.status.value[0] === StatusHabilitation.EN_COURS ||
            this.table.filters.status.value[0] === StatusHabilitation.ECHEANCE_IMMINENTE ||
            this.table.filters.status.value[0] === StatusHabilitation.NON_RENOUVELEE))
        {
            this.filtersToDash.emit(this.table.filters.status.value[0]);
        } else if (this.table.filters.status && this.table.filters.status.value.length === 2 &&
            (this.table.filters.status.value[0] === StatusHabilitation.EN_COURS_RENOUVELLEMENT &&
            this.table.filters.status.value[1] === StatusHabilitation.EN_COURS_RENOUVELLEMENT_DEROGATION) ||
            this.table.filters.status && this.table.filters.status.value.length === 2 &&
            (this.table.filters.status.value[1] === StatusHabilitation.EN_COURS_RENOUVELLEMENT &&
            this.table.filters.status.value[0] === StatusHabilitation.EN_COURS_RENOUVELLEMENT_DEROGATION)
        ) {
            this.filtersToDash.emit(StatusHabilitation.EN_COURS_RENOUVELLEMENT);
        } else if (this.table.filters.status === undefined) {
            // cas aucun filtre statut, on eteint le dashboard et on vide les filtres statut issus du dashboard
            this.filtersToDash.emit(null);
            this.filterFromDash = [];
        } else {
            // cas filtre statut mais ne correspond a aucun element du dashboard, on eteint le dashboard et on met à jour les filtres statut issus du dashboard
            this.filtersToDash.emit(null);
            this.filterFromDash = this.table.filters.status.value;
        }
        this.isTableFlitered = this.table.hasFilter();

        // set the number of element in the list
        this.nbrOfRows = event.filteredValue.length;
    }

    onSelect(field, value) {
        this.table.filter(formatDate(value, 'yyyy-MM', 'fr-FR'), field, 'contains');
    }

    toggleFilter() {
        this.displayFilter = !this.displayFilter;
    }

    onActionClick(actionType: string, id: number) {
        this.action.emit({actionType, id});
    }

    onGroupedActionClick() {
        this.groupedAction.emit(this.selectedData);
    }

    getIcon(field, value){
        // todo: to refacto -- avec le nouvel affichage condditionnel sur dataType de cols ?
        const col = this.cols.find(element => element.field === field);
        const filterRef = col.filterValues.find(element => element.value === value);
        if (filterRef){
            return filterRef.icon;
        }
    }
}
