import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-base',
  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.scss'],
})
export class ModalBaseComponent {

  @Input() display: boolean = false;
  @Input() header: string = '';
  @Input() content: string = '';
  @Input() confirmBtn: boolean = true;
  @Input() cancelBtnTxt: string = 'Annuler';
  @Input() contentStyle: string = '';
  @Output() confirm = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  onConfirmDialog() {
    this.confirm.emit(true);
  }

  onCloseDialog(){
    this.display = false;
    this.cancel.emit(true);
  }


}
