import { Component, OnInit } from '@angular/core';
import { XtlFormateur } from 'src/app/model/xtl-formateur';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StoreService } from 'src/app/service/store.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import {Civility} from '../../enums/civility.enum';
import {FormatValuesService} from '../../service/format-values.service';
import {FormValidatorService} from '../../service/form-validator.service';

@Component({
  selector: 'app-page-formateur-detail',
  templateUrl: './page-formateur-detail.component.html',
  styleUrls: ['./page-formateur-detail.component.scss'],
})
export class PageFormateurDetailComponent implements OnInit {

  formateurForm: FormGroup;
  formateur: XtlFormateur;
  submitted = false;
  itemId: string;

  // lists for form select
  civilityOptions: {label: string, value: string}[];

  constructor(
      private formBuilder: FormBuilder,
      public store: StoreService,
      private activatedRoute: ActivatedRoute,
      private location: Location,
      private formatValuesService: FormatValuesService,
      public formValidator: FormValidatorService
  ) {
  }

  ionViewWillEnter(){
    this.itemId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.itemId)
    {
      this.getSelectedItem();
    }
  }

  ngOnInit() {
    this.formateurForm = this.formBuilder.group({
      civility: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      active: [true],
    });

    this.civilityOptions = this.formatValuesService.formatFilterValues(Object.values(Civility));

  }

  get f() { return this.formateurForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.formateurForm.invalid) {
      return;
    }
    let action: XtlAction = {} as XtlAction;

    if (this.itemId){
      action = {
        type: ActionTypes.UPDATE_FORMATEUR,
        data: Object.assign(this.formateurForm.value, {id: this.itemId})
      };
    } else {
      action = {
        type: ActionTypes.ADD_FORMATEUR,
        data: Object.assign(this.formateurForm.value, {id: null})
      };
    }

    this.store.dispatch(action);
    this.location.back();
  }

  onCancel() {
    this.location.back();
  }

  getSelectedItem(){
    this.store.selectFormateurs(this.itemId).subscribe(
        formateur => {
          if (formateur) {
            this.formateur = formateur;
            this.formateurForm.controls.civility.setValue(this.formateur.civility);
            this.formateurForm.controls.firstname.setValue(this.formateur.firstname);
            this.formateurForm.controls.lastname.setValue(this.formateur.lastname);
            this.formateurForm.controls.active.setValue(this.formateur.active);
          } else {
            const action: XtlAction = {
              type: ActionTypes.FETCH_FORMATEUR,
              data: null
            };
            this.store.dispatch(action);
          }
        });
  }

}
