import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnChanges {

  @Input() parentForm: FormGroup;
  @Input() parentFormControlName: string;
  @Input() placeholder: string;
  @Input() styleClass: string;
  @Input() options: any[];
  @Input() defaultFilter: string;
  @Output() onChange = new EventEmitter<any>();

  formControlName: string;
  selectedValue: string;

  ngOnChanges() {
    // set la valeur du multiselect input
    if (this.defaultFilter !== undefined) {
        this.selectedValue = this.defaultFilter;
    }
  }

  ngOnInit(): void {
    this.formControlName = this.parentFormControlName;
  }

  handleChange($event) {
    this.onChange.emit($event);
  }
}
