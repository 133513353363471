import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ButtonBaseComponent} from './button-base/button-base.component';
import {ButtonValidComponent} from './button-valid/button-valid.component';
import {ButtonCancelComponent} from './button-cancel/button-cancel.component';
import {ModalBaseComponent} from './modal-base/modal-base.component';
import {IconBaseComponent} from './icon-base/icon-base.component';
import {IconCloseComponent} from './icon-close/icon-close.component';
import {IconEnCoursComponent} from './icon-en-cours/icon-en-cours.component';
import {IconEcheanceImminenteComponent} from './icon-echeance-imminente/icon-echeance-imminente.component';
import {IconEnCoursRenouvellementComponent} from './icon-en-cours-renouvellement/icon-en-cours-renouvellement.component';
import { IconEnCoursRenouvellementDerogComponent } from './icon-en-cours-renouvellement-derog/icon-en-cours-renouvellement-derog.component';
import {IconNonRenouveleeComponent} from './icon-non-renouvelee/icon-non-renouvelee.component';
import {IconEchueComponent} from './icon-echue/icon-echue.component';
import {MenuComponent} from './menu/menu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import {Select2Component} from './select2/select2.component';
import {InputComponent} from './input/input.component';
import {DropdownComponent} from './dropdown/dropdown.component';
import {MultiselectComponent} from './multiselect/multiselect.component';
import { ListComponent } from './list/list.component';
import { ListCellComponent } from './list-cell/list-cell.component';
import { SharedModule } from '../shared/shared.module';
import { FieldErrorComponent} from './field-error/field-error.component';
import {HolderHistoryDialogComponent} from './holder-history-dialog/holder-history-dialog.component';
import {ToastComponent} from './toast/toast.component';

import {IonicModule} from '@ionic/angular';
import {ButtonModule} from 'primeng/button';
import {SidebarModule} from 'primeng/sidebar';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TemplateTagDirective } from './template-tag/template-tag.directive';
import {ToastModule} from 'primeng/toast';




@NgModule({
    declarations: [
        HeaderComponent, FooterComponent, MenuComponent,
        ButtonBaseComponent,
        ButtonValidComponent, ButtonCancelComponent,
        DashboardComponent,
        ModalBaseComponent,
        IconBaseComponent,
        IconCloseComponent, IconEnCoursComponent, IconEcheanceImminenteComponent, IconEnCoursRenouvellementComponent, IconEnCoursRenouvellementDerogComponent, IconNonRenouveleeComponent, IconEchueComponent,
        ListComponent, ListCellComponent,
        CalendarComponent, Select2Component, InputComponent, DropdownComponent, MultiselectComponent,
        TemplateTagDirective,
        FieldErrorComponent,
        HolderHistoryDialogComponent,
        ModalBaseComponent, ToastComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        ButtonModule,
        SidebarModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ToastModule
    ],
    exports: [
        HeaderComponent, FooterComponent, MenuComponent,
        ButtonValidComponent, ButtonCancelComponent,
        DashboardComponent,
        IconBaseComponent,
        IconCloseComponent, IconEnCoursComponent, IconEcheanceImminenteComponent, IconEnCoursRenouvellementComponent, IconEnCoursRenouvellementComponent, IconNonRenouveleeComponent, IconEchueComponent,
        ListComponent, ListCellComponent,
        CalendarComponent, Select2Component, InputComponent, DropdownComponent, MultiselectComponent,
        TemplateTagDirective,
        FieldErrorComponent, HolderHistoryDialogComponent, ModalBaseComponent, ToastComponent,
    ]
})
export class UiModule { }
