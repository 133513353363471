import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';


@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})

export class MultiselectComponent implements OnChanges {

  @Input() ngModel: any;
  @Input() styleClass: string;
  @Input() options: any[];
  @Input() placeholder: string;
  @Input() filterFromDash: string[];
  @Input() defaultFilter: string[];
  @Output() onChange = new EventEmitter<any>();

  mutliselectValue: any = [];

  ngOnChanges() {
    // set la valeur du multiselect input
    if (this.defaultFilter !== undefined) {
      if (this.defaultFilter === null) {
        this.mutliselectValue = [];
      } else {
        this.mutliselectValue = this.defaultFilter;
      }
      if (this.filterFromDash === null) {
        this.mutliselectValue = [];
      } else {
        this.defaultFilter = null;
        this.mutliselectValue = this.filterFromDash;
      }
    }
  }

  handleChange($event) {
    this.onChange.emit($event);
  }
}
