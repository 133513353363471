import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { XtlFormation } from 'src/app/model/xtl-formation';
import { StoreService } from 'src/app/service/store.service';
import { ActivatedRoute } from '@angular/router';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import { Location } from '@angular/common';
import {DurationFormation} from '../../enums/duration-formation.enum';
import {FormatValuesService} from '../../service/format-values.service';
import {ValidityFormation} from '../../enums/validity-formation.enum';
import {ColorFormation} from '../../enums/color-formation.enum';
import {FormValidatorService} from '../../service/form-validator.service';

@Component({
  selector: 'app-page-formation-detail',
  templateUrl: './page-formation-detail.component.html',
  styleUrls: ['./page-formation-detail.component.scss'],
})
export class PageFormationDetailComponent implements OnInit {

  formationForm: FormGroup;
  formation: XtlFormation;
  submitted = false;
  itemId: string;

  // lists for form select
  durationOptions: {label: string, value: string}[];
  validityPeriodOptions: {label: string, value: string}[];
  colorsOptions: {label: string, value: string}[] = [];

  constructor(
      private formBuilder: FormBuilder,
      public store: StoreService,
      private activatedRoute: ActivatedRoute,
      private location: Location,
      private formatValuesService: FormatValuesService,
      public formValidator: FormValidatorService
  ) {
  }

  ionViewWillEnter(){
    this.itemId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.itemId)
    {
      this.getSelectedItem();
    }
  }

  ngOnInit() {
    this.formationForm = this.formBuilder.group({
      reference: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      price: ['', Validators.required],
      duration: ['', [Validators.required]],
      validityPeriod: ['', [Validators.required]],
      active: [true],
      color: [''],
      preRequisite: ['']
    });


    this.durationOptions = this.formatValuesService.formatFilterValues(Object.values(DurationFormation));
    this.validityPeriodOptions = this.formatValuesService.formatFilterValues(Object.values(ValidityFormation));
    const colorList = Object.keys(ColorFormation);
    colorList.forEach(key => {
      this.colorsOptions.push({label: '', value: ColorFormation[key]});
    });

  }

  get f() { return this.formationForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.formationForm.invalid) {
      return;
    }
    let action: XtlAction = {} as XtlAction;

    if (this.itemId){
      action = {
        type: ActionTypes.UPDATE_FORMATION,
        data: Object.assign(this.formationForm.value, {id: this.itemId})
      };
    } else {
      action = {
        type: ActionTypes.ADD_FORMATION,
        data: Object.assign(this.formationForm.value, {id: null})
      };
    }

    this.store.dispatch(action);
    this.location.back();
  }

  onCancel() {
    this.location.back();
  }

  getSelectedItem(){
    this.store.selectFormations(this.itemId).subscribe(
      formation => {
        if (formation) {
          this.formation = formation;
          this.formationForm.controls.reference.setValue(this.formation.reference);
          this.formationForm.controls.title.setValue(this.formation.title);
          this.formationForm.controls.description.setValue(this.formation.description);
          this.formationForm.controls.price.setValue(this.formation.price);
          this.formationForm.controls.duration.setValue(this.formation.duration);
          this.formationForm.controls.validityPeriod.setValue(this.formation.validityPeriod);
          this.formationForm.controls.color.setValue(this.formation.color);
          this.formationForm.controls.active.setValue(this.formation.active);
          this.formationForm.controls.preRequisite.setValue(this.formation.preRequisite);
        } else if(this.store.getCurrentUser()!=null){
          const action: XtlAction = {
            type: ActionTypes.FETCH_FORMATION,
            data: null
          };
          this.store.dispatch(action);
        }
      });
    }
}
