import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/service/store.service';
import { Router } from '@angular/router';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import {FormatValuesService} from '../../service/format-values.service';
import {Active} from '../../enums/active.enum';
import {UserRole} from '../../enums/user-role.enum';
import { ShowDialogService } from 'src/app/service/show-dialog.service';
import { CallbackCode } from 'src/app/enums/callback-code.enum';

@Component({
    selector: 'app-page-user-list',
    templateUrl: './page-user-list.component.html',
    styleUrls: ['./page-user-list.component.scss'],
})
export class PageUserListComponent implements OnInit {

    usersTableDatas: any[];
    cols: any[];
    actionButtons = [
        {icon: 'pi pi-pencil', action: 'edit'},
        {icon: 'pi pi-clock', action: CallbackCode.CALLBACK_HOLDER_HISTORY}
    ];

    userFirstnameRef: any[];
    userLastnameRef: any[];
    clientNameRef: any[];
    roleRef: any[];
    activeRef: any[];
    defaultFilter: Array<{ value: string, field: any, matchMode: string }>;


    constructor(
        public store: StoreService,
        private router: Router,
        private formatValuesService: FormatValuesService,
        private showDialogService: ShowDialogService
    ) { }


    ngOnInit() {
        this.defaultFilter = [{ value: 'true', field: 'active', matchMode: 'equals' }]
        this.getUserListData();
    }

    getUserListData() {
        this.activeRef = Object.values(Active);
        this.roleRef = Object.values(UserRole);
        // requête impriquée pour avoir toutes les ref disponible à la création des colones
        this.store.selectClients().subscribe(
            clients => {
                if (clients.length != 0) {
                    this.clientNameRef = [];
                    clients.forEach(client => {
                        this.clientNameRef.push(client.name);
                    });
                    this.store.selectUsers().subscribe(
                        users => {
                            if (users.length !== 0){
                                this.usersTableDatas = [];
                                this.userFirstnameRef = [];
                                this.userLastnameRef = [];
                                users.forEach(user => {

                                    const formatedUser = {
                                        id: user.id,
                                        firstname: user.firstname,
                                        lastname: user.lastname,
                                        role: user.role,
                                        client: user.client.name,
                                        active: user.active
                                    };
                                    this.usersTableDatas.push(formatedUser);
                                    // creation des tableau de ref pour le filtre sur firstname et lastname (avec les doublons)
                                    this.userFirstnameRef.push(user.firstname);
                                    this.userLastnameRef.push(user.lastname);
                                    this.cols = [
                                        {
                                            field: 'lastname',
                                            title: 'Nom',
                                            dataType: 'string',
                                            sort: true,
                                            filterType: 'selectInput',
                                            filterValues: this.formatValuesService.formatFilterValues(this.userLastnameRef),
                                            class: 'width-20'
                                        },
                                        {
                                            field: 'firstname',
                                            title: 'Prénom',
                                            dataType: 'string',
                                            sort: true,
                                            filterType: 'selectInput',
                                            filterValues: this.formatValuesService.formatFilterValues(this.userFirstnameRef),
                                            class: 'width-15'
                                        },
                                        {
                                            field: 'client',
                                            title: 'Espace client',
                                            dataType: 'string',
                                            sort: true,
                                            filterType: 'selectInput',
                                            filterValues: this.formatValuesService.formatFilterValues(this.clientNameRef),
                                            class: 'width-20'
                                        },
                                        {
                                            field: 'role',
                                            title: 'Rôle',
                                            dataType: 'string',
                                            sort: true,
                                            filterType: 'dropdown',
                                            filterValues: this.formatValuesService.formatFilterValues(this.roleRef),
                                            class: 'width-15'
                                        },
                                        {
                                            field: 'active',
                                            title: 'Actif',
                                            dataType: 'active',
                                            sort: true,
                                            filterType: 'dropdown',
                                            defaultFilter: 'true',
                                            filterValues: this.formatValuesService.formatFilterValues(this.activeRef),
                                            class: 'width-10'
                                        },
                                    ];
                                });

                            } else {
                                const action: XtlAction = {
                                    type: ActionTypes.FETCH_USER,
                                    data: null
                                };
                                this.store.dispatch(action);
                            }
                        }
                    );
                } else {
                    const action: XtlAction = {
                        type: ActionTypes.FETCH_CLIENT,
                        data: null
                    };
                    this.store.dispatch(action);
                }
            }
        );
    }

    onAction(_action: {actionType: string, id: number}) {
        switch (_action.actionType){
            case 'create':
                this.router.navigate(['/user-create']);
                break;
            case 'edit':
                this.router.navigate(['/user-detail/' + _action.id]);
                break;
            case CallbackCode.CALLBACK_HOLDER_HISTORY:
                this.showDialogService.showHolderHistoryDialog(_action.id, CallbackCode.CALLBACK_HOLDER_HISTORY);
                break;
        }
    }

}
