// Liste des durées de dérogation pour les habilitations
export enum DurationDerogation {
    ONE_WEEK = '1 WEEK',
    TWO_WEEK = '2 WEEK',
    THREE_WEEK = '3 WEEK',
    FOUR_WEEK = '4 WEEK',
    FIVE_WEEK = '5 WEEK',
    SIX_WEEK = '6 WEEK',
    SEVEN_WEEK = '7 WEEK',
    EIGHT_WEEK = '8 WEEK',
    NINE_WEEK = '9 WEEK',
    TEN_WEEK = '10 WEEK',
    ELEVEN_WEEK = '11 WEEK',
    TWELVE_WEEK = '12 WEEK'
}
