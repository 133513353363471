import { Injectable } from '@angular/core';
import {Storage} from "@ionic/storage";
import {UserService} from "./user.service";
import {StoreService} from "./store.service";

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
      private storage: Storage,
      private userService: UserService,
      private storeService: StoreService,
  ) { }

  Init() {
    return new Promise<void>((resolve, reject) => {
      // a l'init si l'user est toujours connecté on doit récupérer le currentUser avant de charger le reste
      if (!this.storeService.getCurrentUser()) {
        this.storage.get('userId').then(userId => {
          if (userId) {
            this.userService.get(userId).subscribe(
                result => {
                  // L'utilisateur courant est rafraichi dans le store
                  this.storeService.refreshUser(result.entities.users[userId]);
                  resolve();
                },
                error => {
                  // Si erreur de récuperation de l'utilisateur on laisse passer, le guard redirigera vers le login
                  resolve();
                }
            );
            this.storeService.refreshUser(userId);
          } else {
            // Si pas d'utilisateur à rafraichir on laisse passer, le guard redirigera vers le login
            resolve();
          }
        });
      } else {
        // Si utilisateur déjà renseigné dans le store pas besoin de le rafraichir (ce cas ne devrait pas arriver)
        resolve();
      }
    });
  }
}
