import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  showConfirmDialog: boolean = false;

  cguText:string = `
  <h1>Conditions générales d'utilisation</h1>
  <p><i>En vigueur au 01/11/2020</i></p>
   
  <p>Les présentes conditions générales d'utilisation (dites « <b>CGU</b> ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par SDCEM et de définir les conditions d’accès et d’utilisation des services par « <b>l'Utilisateur</b> ».<br>
  Les présentes CGU sont accessibles sur le site à la rubrique «<b>CGU</b>».</p>
  
  
  <h1>Article 1 : Les mentions légales</h1>
  <p>L'édition du site SDCEM ACADEMY est assurée par la Société SAS SDCEM  au capital de 1 004 000 euros, immatriculée au RCS de Grenoble sous le numéro 057 502 668, dont le siège social est situé ZAC du Saut du Moine 38800 Champagnier.<br>
  Numéro de téléphone 04 76 72 76 72<br>
  L'hébergeur du site SDCEM ACADEMY est la société OVH, dont le siège social est situé au 2 rue Kellermann -  59100 Roubaix – France</p>
  
  
  <h1>ARTICLE 2 : Accès au site</h1>
  <p>Le site SDCEM ACADEMY permet à l'Utilisateur un accès gratuit aux services suivants :
  Le site internet propose les services suivants :<br>
  Enregistrement et Suivi des habilitations de réglage des appareils de la société SDCEM.<br>
  Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet.<br> Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>
  
  
  <h1>ARTICLE 3 : Collecte des données</h1>
  <p>Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.<br>
  En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.<br> L'Utilisateur exerce ce droit :
  <ul><li>         par mail à l'adresse mail f.roger@sdcem.com</li>
  <li>         par voie postale au ZAC du Saut du Moine - 38800 CHAMPAGNIER;</li></ul></p>
  
   
  <h1>ARTICLE 4 : Propriété intellectuelle</h1>
  <p>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.<br>
  L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.<br>
  Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.<br>
  Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.</p>
   
  
  <h1>ARTICLE 5 : Responsabilité</h1>
  <p>Les sources des informations diffusées sur le site SDCEM ACADEMY sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.<br>
  Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site SDCEM ACADEMY ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.<br>
  Le site SDCEM ACADEMY ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site.<br>
  La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.</p>
  
  
  
  <h1>ARTICLE 6 : Droit applicable et juridiction compétente</h1>
  <p>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.<br>
  Pour toute question relative à l’application des présentes CGU, vous pouvez joindre le propriétaire du site, SDCEM.</p>
  `;

  onCGUClick() {
    this.showConfirmDialog = true;
  }

  onCloseCGUModal() {
    this.showConfirmDialog = false;
  }

  ngOnInit() {}

}
