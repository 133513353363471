import {Component, Input, OnInit} from '@angular/core';
import {StatusHabilitation} from '../../enums/status-habilitation.enum';

@Component({
  selector: 'app-list-cell',
  templateUrl: './list-cell.component.html',
  styleUrls: ['./list-cell.component.scss'],
})
export class ListCellComponent implements OnInit {

  @Input() col;
  @Input() dataField;
  @Input() dataId = '';
  @Input() exemptionPeriod: string = null;
  statushabilitation = StatusHabilitation;
  constructor() { }

  ngOnInit() {}

  // getIcon(field, value){
  //   // todo: to refacto -- avec le nouvel affichage condditionnel sur dataType de cols ?
  //   const col = this.cols.find(element => element.field === field);
  //   const filterRef = col.filterValues.find(element => element.value === value);
  //   if (filterRef){
  //     return filterRef.icon;
  //   }
  // }
}
