import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/service/store.service';
import { Router } from '@angular/router';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import {FormatValuesService} from '../../service/format-values.service';
import {Active} from '../../enums/active.enum';

@Component({
    selector: 'app-page-formateur-list',
    templateUrl: './page-formateur-list.component.html',
    styleUrls: ['./page-formateur-list.component.scss'],
})
export class PageFormateurListComponent implements OnInit {

    formateursTableDatas: any[];
    cols: any[];
    activeRef: any[] = [];
    formateurRef: any[] = [];
    actionButtons = [
        {icon: 'pi pi-pencil', action: 'edit'}
    ];

    formateursRef: any[];
    defaultFilter: Array<{ value: string, field: any, matchMode: string }>;

    constructor(
        public store: StoreService,
        private router: Router,
        private formatValuesService: FormatValuesService
    ) { }

    ngOnInit() {
        this.defaultFilter = [{ value: 'true', field: 'active', matchMode: 'equals' }]
        this.getFormateurListData();
    }

    getFormateurListData() {
        this.activeRef = Object.values(Active);
        this.store.selectFormateurs().subscribe(
            formateurs => {
                if (formateurs && formateurs.length !== 0){
                    this.formateursTableDatas = new Array();
                    formateurs.forEach(formateur => {
                        const formatedFormateur = {
                            id: formateur.id,
                            firstname: formateur.firstname + ' ' + formateur.lastname,
                            active: formateur.active
                        };
                        this.formateurRef.push(formateur.firstname + ' ' + formateur.lastname);
                        this.formateursTableDatas.push(formatedFormateur);
                    });
                } else {
                    const action: XtlAction = {
                        type: ActionTypes.FETCH_FORMATEUR,
                        data: null
                    };
                    this.store.dispatch(action);
                }
                this.cols = [
                    {
                        field: 'firstname',
                        title: 'Formateurs',
                        dataType: 'string',
                        sort: true,
                        filterType: 'selectInput',
                        filterValues: this.formatValuesService.formatFilterValues(this.formateurRef),
                        class: 'width-50'
                    },
                    {
                        field: 'active',
                        title: 'Actif',
                        dataType: 'active',
                        sort: true,
                        filterType: 'dropdown',
                        defaultFilter: 'true',
                        filterValues: this.formatValuesService.formatFilterValues(this.activeRef),
                        class: 'width-30'
                    },
                ];
            }
        );
    }

    onAction(_action: {actionType: string, id: number}) {
        switch (_action.actionType){
            case 'create':
                this.router.navigate(['/formateur-create']);
                break;
            case 'edit':
                this.router.navigate(['/formateur-detail/' + _action.id]);
                break;
            case 'pdf':

                break;
        }
    }
}
