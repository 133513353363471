import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-base',
  templateUrl: './icon-base.component.html',
  styleUrls: ['./icon-base.component.scss'],
})
export class IconBaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
