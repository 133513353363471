import { Injectable } from '@angular/core';
import { unitOfTime } from 'moment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateCalculatorService {

  constructor() { }

  dateAddDuration(date: string, duration: string): Date {
    const unitsOfTime = duration.split(' ')[1].toLowerCase() as unitOfTime.DurationConstructor;
    const amountOfTime = Number(duration.split(' ')[0]);
    const newDate = moment(date).add(amountOfTime, unitsOfTime).format();
    return new Date(newDate);
  }

  dateSubstractDuration(date: string, duration: string): Date {
    const unitsOfTime = duration.split(' ')[1].toLowerCase() as unitOfTime.DurationConstructor;
    const amountOfTime = Number(duration.split(' ')[0]);
    const newDate = moment(date).subtract(amountOfTime, unitsOfTime).format();
    return new Date(newDate);
  }

  dateSetHour(date: string, hour: number, minute: number = 0, second: number = 0): Date {
    const newDate = moment(date);
    newDate.set({hour: hour, minute: minute, second: second, millisecond: 0});
    return new Date(newDate.format());
  }
}
