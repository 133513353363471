import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {

  @Input() placeholder: string;
  @Input() styleClass: string;
  @Output() input = new EventEmitter<any>();

  handleInput($event) {
    this.input.emit($event);
  }
}
