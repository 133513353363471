import { schema } from 'normalizr';

export interface XtlEmail {
  id: string;
  mailSubject: string;
  mailBody: string;
  createdAt: Date;
  updatedAt: Date;
}

const emailSchema = new schema.Entity('emails');

const emailListSchema = [emailSchema];

export {emailSchema};

export {emailListSchema};
